import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { updateData } from "../../store/Slices/UpdateData";

export default function useMemberShipApi() {
  const [mainData, setMainData] = useState({});
  const [isloading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { accessToken } = useSelector((state) => state.Auth);

  //! get Memberships
  const getMemberShip = async ({ page, memberId }) => {
    setIsLoading(true);
    try {
      if (accessToken) {
        const response = await axiosPrivate.get(
          `membership/pagination/${memberId}?page=${page}`
        );
        setMainData(response);
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  //! Add Membership
  const addMembership = async (body) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post("membership", body);
      console.log(response);
      toast.success(response.data.message);
      window.history.back();
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  //! Get One MemberShips
  const getOneMemberShip = async (id) => {
    setIsLoading(true);
    try {
      if (accessToken && id) {
        const response = await axiosPrivate.get(`membership/${id}`);
        setMainData(response.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  //! Delete Membership
  const deleteMembership = async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.delete(`membership/${id}`);
      toast.success(response.data.message);
      dispatch(updateData(true));
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    mainData,
    isloading,
    getMemberShip,
    addMembership,
    getOneMemberShip,
    deleteMembership,
  };
}
