import { Button, Label, TextInput } from "flowbite-react";
import { BsCheckCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import useGymApi from "../../Api/Admin/useGymApi";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";

export default function AddStaff() {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state) => state.Auth);
  const { getGyms, mainData } = useGymApi();
  const { postStaff } = useEmployeeApi();
  const { handleSubmit, register, reset } = useForm();
  const [multiData, setMultiData] = useState();
  const [confirmPass, setConfirmPass] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    getGyms();
    // eslint-disable-next-line
  }, [accessToken]);

  const employeeIds = useMemo(() => {
    if (mainData?.data) {
      return mainData.data.map((item) => ({
        value: item._id,
        label: item.name,
      }));
    }
  }, [mainData?.data]);

  const password = useMemo(() => {
    return confirmPass?.password === confirmPass?.confirmPassword;
  }, [confirmPass]);

  function handleMultiSelect(multiData) {
    console.log(multiData);
    setMultiData(multiData);
  }

  //? submit
  function onsubmit(data) {
    const mainFormData = {
      employerId: mainData?.data[0]?.employerId,
      ...data,
      gymIds: multiData.map((item) => item.value),
    };
    postStaff(mainFormData);
    reset();
  }

  return (
    <div className="mx-[25px] mt-[15px]">
      <form onSubmit={handleSubmit(onsubmit)}>
        <div className="flex w-full gap-4 border-2 p-[15px] rounded-xl">
          <div className="flex-1">
            <div className="flex items-center gap-[10px] dark:text-white">
              <BsCheckCircleFill className="scale-150" />
              <h2 className="text-xl font-semibold">{t("newStaff")}</h2>
            </div>
            <p className="dark:text-white mt-[10px]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div>
          <div className="flex-1">
            <div>
              <div className="mb-3 block">
                <Label
                  htmlFor="fullname"
                  className="text-xs uppercase"
                  value={t("fullname")}
                />
              </div>
              <TextInput
                id="fullname"
                type="text"
                sizing="md"
                name="fullname"
                {...register("fullname")}
                // onChange={(e) => handleInputValue(e)}
              />
            </div>

            <div>
              <div className="mb-3 block">
                <Label
                  htmlFor="email"
                  className="text-xs uppercase"
                  value={t("Email")}
                />
              </div>
              <TextInput
                id="email"
                type="email"
                sizing="md"
                name="email"
                {...register("email")}
                // onChange={(e) => handleInputValue(e)}
              />
            </div>

            <div>
              <div className="mb-3 block">
                <Label
                  htmlFor="chekincode"
                  value={t("phone")}
                  className="text-xs uppercase"
                />
              </div>
              <TextInput
                id="chekincode"
                type="tel"
                sizing="md"
                name="phone"
                {...register("phone")}
                // onChange={(e) => handleInputValue(e)}
              />
            </div>
          </div>

          <div className="flex-1">
            <div>
              <div className="mb-3 block">
                <Label
                  htmlFor="gender"
                  className="text-xs uppercase"
                  value={t("gyms")}
                />
              </div>
              <MultiSelect
                handleMultiSelect={handleMultiSelect}
                data={employeeIds}
                isRequared={true}
              />
            </div>
            <div>
              <div className="mb-3 block">
                <Label
                  htmlFor="pass"
                  className="text-xs uppercase"
                  value={t("passwordMin")}
                />
              </div>
              <TextInput
                id="pass "
                type="password"
                sizing="md"
                required
                placeholder="********"
                name="password"
                {...register("password")}
                onChange={(e) => {
                  // handleInputValue(e);
                  setConfirmPass({ ...confirmPass, password: e.target.value });
                }}
              />
            </div>
            <div>
              <div className="mb-3 block">
                <Label
                  htmlFor="confirm"
                  className={`text-xs uppercase `}
                  style={{
                    color: password ? "" : "red",
                  }}
                  value={t("confirmPassword")}
                />
              </div>
              <TextInput
                id="confirm "
                type="password"
                sizing="md"
                placeholder="********"
                onChange={(e) =>
                  setConfirmPass({
                    ...confirmPass,
                    confirmPassword: e.target.value,
                  })
                }
              />
            </div>{" "}
          </div>
        </div>
        <div className="flex justify-end mt-[20px]">
          <Button className="text-black" disabled={!password} type="submit">
            {t("submit")}
          </Button>
        </div>
      </form>
    </div>
  );
}
