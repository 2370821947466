import React, { useEffect, useState } from "react";
import SuperTable from "../components/SuperTable/SuperTable";
import { createTable } from "../utils/createTable";
import { Link, useParams } from "react-router-dom";
import useEmployerApi from "../Api/Admin/useEmployerApi";
import { useSelector } from "react-redux";
import useEmployeeApi from "../Api/Employer/useEmployeeApi";
import TableSkleton from "../components/TableSkleton/TableSkleton";
import NotFoundData from "../components/NotFound/NotFoundData";

export default function Profile({ role }) {
  const [table, setTable] = useState([]);
  const { employerId, staffId } = useParams();
  const { accessToken } = useSelector((state) => state.Auth);
  const { getOneEmployer, isLoader, mainData: user, error } = useEmployerApi();
  const {
    employerStaffs,
    isLoader: employerStaffsLoader,
    mainData: staff,
    error: staffErr,
  } = useEmployerApi();
  const {
    getOneStaff,
    mainData: staffInfo,
    isLoader: staffLoader,
    error: oneStaffErr,
  } = useEmployeeApi();

  useEffect(() => {
    if (role === "admin") {
      getOneEmployer(employerId);
      employerStaffs(employerId);
    }
    if (role === "employer") {
      getOneStaff(staffId);
    }
    // eslint-disable-next-line
  }, [accessToken, staffId]);
  const employerTable = createTable(staff?.employerStaffs?.data || []);
  const staffTable = createTable(staffInfo?.data?.gymIds || []);

  return (
    <>
      <h2 className="text-[25px] ml-[15px] font-semibold dark:text-white  ">
        {role === "admin" && isLoader
          ? "Loading..."
          : user?.oneEmployer?.data?.fullname}

        {role === "employer" && staffInfo?.data?.fullname}
      </h2>
      <Link to={""}>
        <h3 className="ml-[15px] text-[18px] font-medium underline dark:text-white">
          {role === "admin" && user?.oneEmployer?.data?.phone}
        </h3>
      </Link>
      {!employerStaffsLoader &&
      !staffLoader &&
      !isLoader &&
      (staffErr.status ||
        oneStaffErr.status ||
        (!employerTable?.data?.length > 0 && role === "admin") ||
        (!staffTable?.data?.length > 0 && role === "employer")) ? (
        <NotFoundData
          title={
            staffErr?.message ||
            oneStaffErr.message ||
            "Employerni Employee malumotlari mavjud emas"
          }
          height="calc(100vh - 180px)"
        />
      ) : (
        <div className="p-[15px] ">
          {role === "admin" &&
            staff?.employerStaffs?.data &&
            staff?.employerStaffs?.data.length > 0 && (
              <SuperTable
                role={"admin"}
                table={employerTable}
                hiddenKeys={["_id", "gymIds", "employerId"]}
              />
            )}
          {role === "employer" && !staffLoader && table && (
            <SuperTable
              role={"admin"}
              table={staffTable}
              hiddenKeys={[
                "_id",
                "employees",
                "employerId",
                "__v",
                "timeFormat",
                "UTC+5",
              ]}
            />
          )}
          {staffLoader && <TableSkleton />}
        </div>
      )}
    </>
  );
}
