import AdminLayout from "../../layouts/AdminLayout";
import EmployeeLayout from "../../layouts/EmployeeLayout";
import EmployerLayout from "../../Pages/EmployerLayout";
import { Login } from "../../Pages/Auth/Login";

const RequareAuth = ({ allowedRole }) => {
  const role = JSON.parse(localStorage.getItem("role"));

  const layouts = {
    admin: <AdminLayout role={role} />,
    employee: <EmployeeLayout role={role} />,
    employer: <EmployerLayout role={role} />,
  };

  if (role === allowedRole) {
    return layouts[allowedRole];
  } else {
    return <Login />;
  }
};

export default RequareAuth;
