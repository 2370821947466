import React, { useEffect } from "react";
import useGymApi from "../../Api/Admin/useGymApi";
import { useSelector } from "react-redux";
import SuperTable from "../../components/SuperTable/SuperTable";
import { createTable } from "../../utils/createTable";
import TableSkleton from "../../components/TableSkleton/TableSkleton";
import NotFoundData from "../../components/NotFound/NotFoundData";

export default function Gyms() {
  const { accessToken } = useSelector((state) => state.Auth);
  const { getGyms, mainData, isLoader, error } = useGymApi();
  useEffect(() => {
    getGyms();
    // eslint-disable-next-line
  }, [accessToken]);

  const table = createTable(mainData?.data);
  return (
    <div className="px-[20px]">
      {!isLoader && (error?.status || !table?.data?.length) ? (
        <NotFoundData height="calc(100vh - 100px)" title={error?.message || "Gym not found"} />
      ) : (
        <SuperTable
          table={table}
          hiddenKeys={["employerId", "employees", "_id"]}
          view={true}
        />
      )}
      {isLoader && !table && <TableSkleton />}
    </div>
  );
}
