import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import { chart as chartjs } from "chart.js/auto";
import { userData } from "../../static/userData";

export default function EmployerDashboard() {
  const [state, setState] = useState({
    labels: userData?.map((user) => user.year),
    datasets: [
      {
        label: "UserGained",
        data: userData.map((user) => user.userGain),
      },
    ],
  });

  return (
    <div>
      <Bar className="text-[#b2f203]" data={state} />
    </div>
  );
}
