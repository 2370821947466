import { Button, Table } from "flowbite-react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { uid } from "uid";
import useEmployerApi from "../../Api/Admin/useEmployerApi";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";
import useGymApi from "../../Api/Admin/useGymApi";
import formatPrice from "../../utils/formatPrice";
import formatDate from "../../utils/formatDate";
import useConfirm from "../../hooks/useConfirm";

export default function TableItems({
  role,
  data,
  isLink,
  addGym,
  del,
  edit,
  view,
  column,
  setOpenModal,
  OpenModal,
  setGymId,
  confirm,
  type,
}) {
  const { t } = useTranslation();
  const { deleteEmployer } = useEmployerApi();
  const { deleteStaff } = useEmployeeApi();
  const { deleteGym } = useGymApi();

  // const { confirm, ConfirmComponent } = useConfirm();

  const employerDelMethod = {
    admin: deleteEmployer,
    employer: deleteStaff,
  };
  const gymDelMethods = {
    admin: deleteGym,
  };

  const mainRole = useMemo(() => {
    if (role === "admin") return role + "/employers";
    if (role === "employer") return role + "/staff";
    if (role === "employee") return role + "/members";
  }, [role]);

  const editItem = () => {
    setOpenModal(true);
    if (role === "admin") setGymId(data._id);
  };

  const deleteItem = async () => {
    const userConfirmed = await confirm(t("doYouWantToDelete"));
    if (userConfirmed) {
      if (type === "employer") employerDelMethod[role]?.(data._id);

      if (type === "gym") gymDelMethods[role]?.(data._id);
    }
  };

  return (
    <Table.Row className=" dark:border-gray-700 dark:bg-gray-800 bg-slate-50  ">
      {column?.map((key) => {
        if (isLink && key === "fullname") {
          return (
            <Table.Cell key={uid()}>
              <Link to={`/${mainRole}/${data._id}`} className="underline">
                {data[key]}
              </Link>
            </Table.Cell>
          );
        } else if (isLink && key === "phone") {
          return (
            <Table.Cell key={uid()}>
              <Link to={`tel:${data[key]}`} className="underline">
                {data[key]}
              </Link>
            </Table.Cell>
          );
        } else if (key === "image" || key === "logo") {
          return (
            <Table.Cell key={uid()}>
              <div className="flex justify-center items-center w-[40px] rounded-full overflow-hidden">
                <img alt="logo" src={data[key]} />
              </div>
            </Table.Cell>
          );
        } else if (typeof data[key] === "object") {
          if (key === "duration") {
            return (
              <Table.Cell key={uid()}>
                <div className="flex flex-col">
                  <span>
                    {t("from")}: <b>{formatDate(data[key].from)}</b>
                  </span>
                  <span>
                    {t("to")}: <b>{formatDate(data[key].to)}</b>
                  </span>
                </div>
              </Table.Cell>
            );
          } else {
            return (
              <Table.Cell key={uid()}>
                <div className="flex flex-col">
                  <span>
                    {t("from")}: <b>{data[key].from}</b>
                  </span>
                  <span>
                    {t("to")}: <b>{data[key].to}</b>
                  </span>
                </div>
              </Table.Cell>
            );
          }
        } else if (key === "isActive") {
          return (
            <Table.Cell key={uid()}>
              <span
                className={`p-2 font-semibold ${
                  data[key] ? "text-[#b2f203]" : "text-red-600"
                } `}
              >
                {data[key] ? t("active") : t("noActive")}
              </span>
            </Table.Cell>
          );
        } else if (key === "paymentAmount") {
          return <Table.Cell key={uid()}>{formatPrice(data[key])}</Table.Cell>;
        } else if (key === "createdAt") {
          return <Table.Cell key={uid()}>{formatDate(data[key])}</Table.Cell>;
        } else {
          return <Table.Cell key={uid()}>{data[key]}</Table.Cell>;
        }
      })}
      {(del || edit || view || addGym || OpenModal) && (
        <Table.Cell className="flex justify-end">
          <div className="flex gap-2">
            {addGym && (
              <Button className="dark:text-black" size={"sm"} type="button">
                {t("AddGym")}
              </Button>
            )}
            {del && (
              <Button
                className="dark:text-white"
                onClick={() => deleteItem()}
                color="failure"
                size={"sm"}
                type="button"
              >
                {t("delete")}
              </Button>
            )}
            {edit && (
              <Button
                className="dark:text-black"
                onClick={() => editItem()}
                size={"sm"}
                type="button"
              >
                {t("edit")}
              </Button>
            )}
            {view && (
              <Link to={`/employer/gyms/${data._id}`}>
                <Button className="dark:text-black" size={"sm"} type="button">
                  {t("view")}
                </Button>
              </Link>
            )}
            {OpenModal && (
              <Button
                onClick={() => OpenModal({ isOpen: true, id: data._id })}
                className="dark:text-black"
                size={"sm"}
                type="button"
              >
                {t("view")}
              </Button>
            )}
          </div>
        </Table.Cell>
      )}
      
    </Table.Row>
  );
}
