function formatDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const newDate = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
    
    return newDate;
  }
}

export default formatDate;
