import Select from "react-select";

export default function MultiSelect({
  data,
  handleMultiSelect,
  name,
  isRequared,
  oldEmployees,
}) {
  let options = data || [
    { value: "jhlkshdja", label: "malumot you" },
    { value: "jhlkshdja2", label: "malumot you2" },
    { value: "jhlkshdj3", label: "malumot you3" },
    { value: "jhlkshdja4", label: "malumot you4" },
    { value: "jhlkshdj5", label: "malumot you5" },
    { value: "jhlkshdja6", label: "malumot you6" },
  ];

  return (
    <div>
      <Select
        defaultValue={oldEmployees}
        id="select"
        isMulti
        name={name}
        options={options}
        className="basic-multi-select [&>*]:dark:bg-slate-600 [&>*]:dark:text-slate-400 hover:[&>div]:bg-slate-700 "
        classNamePrefix="select"
        onChange={(e) => handleMultiSelect(e)}
        required={isRequared}
      />
    </div>
  );
}
