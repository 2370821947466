import { createSlice } from "@reduxjs/toolkit";

const UpdateData = createSlice({
  name: "updateData",
  initialState: false,
  reducers: {
    updateData(state, action) {
      state = action.payload;
    },
  },
});

export const { updateData } = UpdateData.actions;
export default UpdateData.reducer;
