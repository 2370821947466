const RouteLinksActive = ({
  role,
  SidebarAdminData,
  SidebarEmployerData,
  SidebarEmployeeData,
  location,
}) => {
  const roleRouteData = () => {
    if (role === "admin") {
      return SidebarAdminData;
    } else if (role === "employer") {
      return SidebarEmployerData;
    } else if (role === "employee") {
      return SidebarEmployeeData;
    }
  };

  const newRouteLinks = roleRouteData()?.map((link) => {
    let path = location?.pathname.split("/")[2];
    if (link.items?.length > 0) {
      let newLink = link.items.map((item) => ({
        ...item,
        active: path.includes(item.to),
      }));
      return { ...link, items: newLink };
    } else {
      if (!link.items) {
        return {
          ...link,
          active: link.to ? path === link.to : path === undefined,
        };
      } else {
        return link;
      }
    }
  });

  return newRouteLinks;
};

export { RouteLinksActive };
