import { Navbar, Select, Spinner } from "flowbite-react";
import useGymApi from "../../Api/Admin/useGymApi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { updateData } from "../../store/Slices/UpdateData";
import { useLocation, useNavigate } from "react-router-dom";

export function MainNavbar({ role }) {
  const [gyms, setGyms] = useState([]);
  const [activeGym, setActiveGym] = useState(
    JSON.parse(localStorage.getItem("activeGym"))
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accessToken } = useSelector((state) => state.Auth);
  const { getEmplyeeGyms, isLoader } = useGymApi();

  //? get EmployeeGyms
  useEffect(() => {
    if (accessToken) {
      async function getPendingGyms() {
        const data = await getEmplyeeGyms();
        setGyms(data);
      }
      if (role === "employee") getPendingGyms();
    }
  }, [accessToken]);

  const activeLogo = useMemo(() => {
    const active = gyms.find((item) => item._id === activeGym);
    return active?.logo;
  }, [activeGym, gyms]);

  //? change ActriveGym
  const changeActiveGym = (e) => {
    //logic to change active gym
    setActiveGym(e.target.value);
    dispatch(updateData(false));
    localStorage.setItem("activeGym", JSON.stringify(e.target.value));
    if (pathname !== "/employee/members") {
      navigate("/");
    }
  };

  return (
    <Navbar className="border-b-2 h-[70px] bg-gray-50">
      <div className="flex justify-end w-full">
        <div className="flex gap-3 items-center">
          {isLoader && (
            <Select>
              <option value="isLoading"> Loading...</option>
            </Select>
          )}
          {!isLoader && (
            <Select defaultValue={activeGym} onChange={changeActiveGym}>
              {gyms?.map((item) => (
                <option
                  className="hover:bg-[#b2f203]"
                  key={item._id}
                  value={item._id}
                >
                  {item.name}
                </option>
              ))}
            </Select>
          )}
          <div className="w-[40px] h-[40px] rounded-full bg-slate-100 dark:bg-slate-500 p-[5px] overflow-hidden flex justify-center items-center border border-gray-300">
            {isLoader && <Spinner />}
            {activeLogo && <img className="w-full" src={activeLogo} />}
          </div>
        </div>
      </div>
    </Navbar>
  );
}
