import { Route, Routes } from "react-router-dom";
import { Login } from "./Pages/Auth/Login";
import RequareAuth from "./components/requareAuth";
import PageLayout from "./layouts/PageLayout";
import {
  adminEmplyoyerStaffLayoutData,
  employerLayoutData,
  employerStaffProfileLayoutData,
  gymsLayoutData,
  memberLayoutData,
  memberProfileData,
  stuffLayoutData,
} from "./static/PageLayoutData";
import EmployerList from "./Pages/Admin/EmployerList";
import AddEmployer from "./Pages/Admin/AddEmployer";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import Profile from "./common/Profile";
import GymList from "./common/GymList";
import AddGym from "./common/AddGym";
import Logout from "./Pages/Auth/Logout";
import useRefreshToken from "./hooks/useRefreshToken";
import StaffList from "./Pages/Employer/StaffList";
import AddStaff from "./Pages/Employer/AddStaff";
import Gyms from "./Pages/Employer/Gyms";
import GymInfo from "./Pages/Employer/GymInfo";
import EmployerDashboard from "./Pages/Employer/EmloyerDashboard";
import MemberList from "./Pages/Employee/MemberList";
import AddMember from "./Pages/Employee/AddMember";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MemberDetails from "./Pages/Employee/MemberDetails";
import MemberAttendance from "./Pages/Employee/MemberAttendance";
import AddMemberShip from "./Pages/Employee/AddMemberShip";

import { AdminLogin } from "./Pages/Auth/AdminLogin";
import NotFound from "./components/NotFound/NotFound";
import NotFoundData from "./components/NotFound/NotFoundData";

function App() {
  const refresh = useRefreshToken();
  const { t, i18n } = useTranslation();
  const { gyms } = useSelector((state) => state.staff);

  useEffect(() => {
    if (localStorage.getItem("refreshToken")) {
      refresh();
    }
  }, [i18n]);

  if (!localStorage.getItem("lang")) {
    i18n.changeLanguage("en");
    localStorage.setItem("lang", JSON.stringify("en"));
  }

  useEffect(() => {
    if (gyms[0]?._id && !localStorage.getItem("activeGym")) {
      localStorage.setItem("activeGym", JSON.stringify(gyms[0]?._id));
    }
    localStorage.setItem("flowbite-theme-mode", "dark");
  }, [gyms]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="*" element={<NotFound />} />
      {/* <Route path="*" element={<NotFoundData />} /> */}

      {/*//!Admin Routes */}
      <Route path="admin" element={<RequareAuth allowedRole={"admin"} />}>
        <Route index element={<AdminDashboard />} />
        <Route
          path="employers"
          element={<PageLayout items={employerLayoutData} />}
        >
          <Route index element={<EmployerList />} />
          <Route path="add" element={<AddEmployer />} />
        </Route>

        {/*//! Profile Admin Employer*/}
        <Route
          path="employers/:employerId"
          element={
            <PageLayout role={"admin"} items={adminEmplyoyerStaffLayoutData} />
          }
        >
          <Route index element={<Profile role={"admin"} />} />
          <Route path="gymList" element={<GymList role={"admin"} />} />
          <Route path="addgym" element={<AddGym role={"admin"} />} />
        </Route>
      </Route>

      {/*//! Employer Routes */}
      <Route path="employer" element={<RequareAuth allowedRole={"employer"} />}>
        <Route index element={<EmployerDashboard />} />

        <Route
          path="gyms"
          element={<PageLayout role={"employer"} items={gymsLayoutData} />}
        >
          <Route index element={<Gyms />} />
          <Route path="add" element={<AddGym role={"employer"} />} />
        </Route>
        
        <Route path="/employer/gyms/:gymId" element={<GymInfo />} />
        
        <Route
          path="staff"
          element={<PageLayout role={"employer"} items={stuffLayoutData} />}
        >
          <Route index element={<StaffList />} />
          <Route path="add" element={<AddStaff />} />
        </Route>

        {/*//! Profile employer staff*/}
        <Route
          path="staff/:staffId"
          element={
            <PageLayout
              role={"employer"}
              items={employerStaffProfileLayoutData}
            />
          }
        >
          <Route index element={<Profile role={"employer"} />} />
          <Route path="gymList" element={<GymList role={"employer"} />} />
          <Route path="addgym" element={<AddGym role={"employer"} />} />
        </Route>
      </Route>

      {/*//! Employee Routes */}
      <Route path="employee" element={<RequareAuth allowedRole={"employee"} />}>
        <Route index element={<EmployerDashboard />} />
        <Route
          path="members"
          element={<PageLayout role={"employee"} items={memberLayoutData} />}
        >
          <Route index element={<MemberList />} />
          <Route path="addmember" element={<AddMember />} />
        </Route>
        <Route
          path="members/:memberId"
          element={<PageLayout role={"employee"} items={memberProfileData} />}
        >
          <Route index element={<MemberDetails />} />
          <Route path="addmembership" element={<AddMemberShip />} />
          <Route path="attendance" element={<MemberAttendance />} />
        </Route>
      </Route>
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
