import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { toast } from "react-toastify";
import { updateData } from "../../store/Slices/UpdateData";

export default function useEmployerApi() {
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({
    status: false,
  });
  const [mainData, setMainData] = useState({});
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const { accessToken } = useSelector((state) => state.Auth);

  //! Get Employer
  const getEmployers = async (page) => {
    try {
      if (accessToken) {
        setError({
          status: false,
        });
        setIsLoader(true);
        const response = await axiosPrivate.get(
          `employer/pagination?page=${page}`
        );
        setMainData(response.data);
      }
    } catch (error) {
      setError({
        status: true,
        message: error.message,
      });
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  //! Post Emmployer
  const postEmployer = async (body) => {
    try {
      setIsLoader(true);
      const response = await axiosPrivate.post("employer/register", {
        ...body,
      });
      toast.success(response.data.message);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Get One Employer
  const getOneEmployer = async (id) => {
    if (accessToken) {
      setError({
        status: false,
      });
      setIsLoader(true);
      try {
        const response = await axiosPrivate.get(`employer/${id}`);
        setMainData({ ...mainData, oneEmployer: response.data });
      } catch (error) {
        setError({
          status: true,
          message: error.message
        })
        console.log(error);
      } finally {
        setIsLoader(false);
      }
    }
  };
  //! Get Employer Staffs
  const employerStaffs = async (id) => {
    if (accessToken) {
      try {
        setError({
          status: false,
        });
        const response = await axiosPrivate.get(
          `employee/pagination?employerId=${id}`
        );
        setMainData({ ...mainData, employerStaffs: response.data });
      } catch (err) {
        setError({
          status: true,
          message: err.message
        });
        console.log(err);
      } finally {
      }
    }
  };

  //! Get Employer Gyms
  const employerGyms = async (id) => {
    if (accessToken) {
      setError({
        status: false,
      });
      setIsLoader(true);
      try {
        const response = await axiosPrivate.get(`gym/all?employerId=${id}`);
        setMainData({ ...mainData, gyms: response.data });
      } catch (err) {
        setError({
          status: true,
          message: err.message
        });
        console.log(err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  //! Delete Employer
  const deleteEmployer = async (id) => {
    if (accessToken) {
      setIsLoader(true);
      try {
        const response = await axiosPrivate.delete(`employer/${id}`);
        dispatch(updateData(true));
        toast.success(response.data.message);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  // // Put Employer
  // const putEmployer = async (id, data) => {
  //   if (accessToken) {
  //     setIsLoader(true);
  //     try {
  //       const response = await axiosPrivate.put(`employer/${id}`, data);
  //       setMainData({ ...mainData, oneEmployer: response.data });
  //       toast.success("Employer updated successfully");
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setIsLoader(false);
  //     }
  //   }
  // };

  return {
    getEmployers,
    postEmployer,
    getOneEmployer,
    employerStaffs,
    employerGyms,
    deleteEmployer,
    isLoader,
    error,
    mainData,
  };
}
