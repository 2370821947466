import React, { useState } from "react";
import Confirm from "../components/confirm/Confirm";

const useConfirm = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [resolve, setResolve] = useState(null);

  const confirm = (msg) => {
    return new Promise((res) => {
      setMessage(msg);
      setIsVisible(true);
      setResolve(() => res);
    });
  };

  const handleResolve = (result) => {
    setIsVisible(false);
    if (resolve) resolve(result);
  };

  const ConfirmComponent = isVisible ? (
    <Confirm message={message} onResolve={handleResolve} />
  ) : null;

  return { confirm, ConfirmComponent };
};

export default useConfirm;
