import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { updateData } from "../../store/Slices/UpdateData";
import { useNavigate } from "react-router-dom";

function useGymApi() {
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: "",
  });
  const [mainData, setMainData] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.Auth);

  //! Get Gyms
  const getGyms = async () => {
    if (accessToken) {
      setIsLoader(true);
      setError({
        status: false,
      });
      try {
        const response = await axiosPrivate.get(`gym/all`);

        setMainData(response.data);
      } catch (err) {
        setError({
          status: true,
          message: err.message || "Gyms not found",
        });
        console.log(err);
      } finally {
        setIsLoader(false);
      }
    }
  };
  //! Get One Gym
  const getOneGym = async (gymId) => {
    if (accessToken) {
      setIsLoader(true);
      try {
        const response = await axiosPrivate.get(`gym/${gymId}`);
        setMainData(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  //! Post Gyms
  const postGym = async ({ formdata, employerId }) => {
    if (accessToken) {
      setIsLoader(true);
      try {
        const response = await axiosPrivate.post(
          employerId ? `gym?employerId=${employerId}` : "gym",
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success(response.data.message);
        window.history.back();
      } catch (err) {
        console.log(err);
        toast.error(t(err.response.data.message));
      } finally {
        setIsLoader(false);
      }
    }
  };
  //! Get EmployeeGyms
  const getEmplyeeGyms = async () => {
    if (accessToken) {
      setIsLoader(true);
      try {
        const response = await axiosPrivate.get("gym/employeeGyms");
        dispatch(updateData(true));
        return response.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoader(false);
      }
    }
  };

  //! Put Gym
  const updateGym = async ({ body, id }) => {
    if (accessToken) {
      setIsLoader(true);
      try {
        const response = await axiosPrivate.put(`gym/${id}`, body);
        toast.success(response.data.message);
        dispatch(updateData(true));
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
      } finally {
        setIsLoader(false);
      }
    }
  };

  //! Delete Gym
  const deleteGym = async (id) => {
    if (accessToken) {
      setIsLoader(true);
      try {
        const response = await axiosPrivate.delete(`gym/${id}`);
        toast.success(response.data.message);
        window.history.back();
        dispatch(updateData(true));
      } catch (err) {
        console.log(err);
        toast.error(err.response.data.message);
      } finally {
        setIsLoader(false);
      }
    }
  };

  return {
    getGyms,
    getOneGym,
    postGym,
    updateGym,
    deleteGym,
    getEmplyeeGyms,
    isLoader,
    mainData,
    error
  };
}

export default useGymApi;
