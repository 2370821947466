import { Pagination } from "flowbite-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateData } from "../../store/Slices/UpdateData";

export default function SuperPagination({ setPage, minPage = 1, maxPage }) {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
    dispatch(updateData());
  };

  return (
    <div className="flex overflow-x-auto sm:justify-center">
      <Pagination
        currentPage={currentPage}
        totalPages={maxPage}
        onPageChange={onPageChange}
        showIcons
      />
    </div>
  );
}
