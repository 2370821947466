import React, { useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { activeClass } from "../../utils/activeClass";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";

export default function PageNavbar({ title, items, role }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const locationPath = useMemo(() => {
    if (role === "admin") {
      return "/admin/employers";
    } else if (role === "employer") {
      return "/employer/staff";
    } else {
      return "/employee/members";
    }
  }, [role]);

  return (
    <nav className="flex flex-col px-[15px] pb-[10px] pt-3">
      {title && (
        <div className="flex justify-between">
          <h2 className="text-[25px] block ml-[15px] font-semibold dark:text-white">
            {t(title)}
          </h2>
          <Button
            className="dark:text-black"
            onClick={() => navigate(locationPath)}
            size={"sm"}
          >
            {t("backToList")}
          </Button>
        </div>
      )}
      <ul className="flex border-b-[1px] border-b-slate-300">
        {items?.map((item, index) => (
          <NavLink
            className={({ isActive }) =>
              activeClass(
                "dark:hover:border-b-[#b2f203] transition-all border-2 border-transparent hover:border-b-2",
                "border-b-2 dark:border-b-[#b2f203] border-b-black ",
                "border-b-0",
                isActive
              )
            }
            end
            key={index}
            to={item.link}
          >
            <li className="dark:text-white text-normal text-[17px] p-[10px] cursor-pointer ">
              {t(item.text)}
            </li>
          </NavLink>
        ))}
      </ul>
    </nav>
  );
}
