import React, { useEffect, useRef, useState } from "react";
import { FaSearch, FaFilter } from "react-icons/fa";
import { IoMdPrint } from "react-icons/io";
import { GrDocumentTransfer } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Button, Spinner, Table } from "flowbite-react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";
import SuperTable from "../../components/SuperTable/SuperTable";
import { createTable } from "../../utils/createTable";
import SuperPagination from "../../components/pagination/SuperPagination";
import NotFoundData from "../../components/NotFound/NotFoundData";

export default function MemberList({ role }) {
  // const []
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrop, setIsDrop] = useState(true);
  const { accessToken } = useSelector((state) => state.Auth);
  const { getMembers, mainData, isLoader, error } = useEmployeeApi();
  const activeGym = JSON.parse(localStorage.getItem("activeGym"));
  const update = useSelector((state) => state.update);

  useEffect(() => {
    const serchText = searchQuery.trim();
    if (accessToken && !serchText) {
      getMembers(activeGym);
    }
    if (serchText) {
    }
  }, [accessToken, update]);

  const membersRef = useRef(null);

  const handleExport = () => {
    console.log("hello");
    const input = membersRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210; // PDFning kengligi millimetrda
      const pageHeight = 295; // PDFning balandligi millimetrda
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("members.pdf");
    });
  };

  const handleExportExel = () => {
    const table = membersRef.current;
    const workbook = XLSX.utils.table_to_book(table);
    const ws = workbook.Sheets["Sheet1"];
    ws["A1"].s = { font: { bold: true, color: { rgb: "FF0000" } } };

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "membersExel.xlsx");
  };

  const table = createTable(mainData);

  return (
    <div className="dark:shadow-none rounded-lg">
      <div className="flex items-center justify-between">
        {/* <div className="flex gap-3 p-[20px]">
          <form className="flex items-center rounded-full bg-[#f3f4f7] text-[15px] pl-3 py-[1px] pr-[2px]">
            <FaSearch size={20} color="#593f54" />

            <input
              type="text"
              placeholder="Search phone number"
              className="outline-none border-none bg-transparent focus:outline-none focus:ring-0 w-[260px]"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            
            <button
              type="button"
              className=" h-full flex items-center p-[2px_20px] bg-[#b2f203] rounded-r-full"
            >
              <FaFilter size={18} />
             
              <span>Filter</span>
            </button>
          </form>
        </div> */}

        {/* <div className="flex items-center gap-4 mr-4">
          <Button className="dark:text-black">
            <IoMdPrint size={20} />
            print
          </Button>
          <div className="relative">
            <Button
              className="dark:text-black"
              onClick={() => setIsDrop(!isDrop)}
            >
              <GrDocumentTransfer size={20} />
              <span>export</span>
            </Button>
            <div
              style={{
                display: isDrop ? "none" : "block",
              }}
              className="flex flex-col gap-1 bg-white mt-[10px] absolute right-0 h-auto z-10 transition-all rounded "
            >
              <button
                className="py-2 px-[50px] border-b-2 "
                onClick={() => handleExport()}
              >
                PDF
              </button>
              <button
                className="py-2 px-[50px]"
                onClick={() => handleExportExel()}
              >
                EXEL
              </button>
            </div>
          </div>
        </div> */}
      </div>

      <div className="p-[15px] ">
        <div className="pb-3">
          {isLoader && (
            <div className="flex justify-center w-full">
              <Spinner size={"xl"} />
            </div>
          )}
          {!isLoader && (error.status || !table?.data?.length) ? (
            <NotFoundData title={error.message || "Members not found"} height="calc(100vh - 150px)" />
          ) : (
            <SuperTable
              table={table}
              hiddenKeys={["_id", "birthdate", "gymId"]}
              isLink={true}
              role={"employee"}
            />
          )}
          <div className="p-3">
            {/* {maxPage && maxPage >= 1 && (
          <SuperPagination
            setPage={setPage}
            maxPage={maxPage}
            className="mt-auto"
          />
        )} */}
          </div>
        </div>
      </div>

      <div
        ref={membersRef}
        className="bg-white absolute top-0 left-[-100%] p-[20px]"
      >
        <h3 className=" font-medium pl-[20px] pb-[10px] text-[30px]">
          Members
        </h3>
        {/* <Table>
          <Table.Head className="dark:bg-white">
            <Table.HeadCell className=" dark:bg-white dark:text-black">
              MEMBER
            </Table.HeadCell>
            <Table.HeadCell className=" dark:bg-white dark:text-black">
              contact
            </Table.HeadCell>
            <Table.HeadCell className=" dark:bg-white dark:text-black">
              membership
            // </Table.HeadCell>
            <Table.HeadCell className=" dark:bg-white dark:text-black">
              lastvist
            </Table.HeadCell>
            <Table.HeadCell className=" dark:bg-white dark:text-black">
              billing
            </Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {table?.data.map((row) => (
              <Table.Row className="bg-white dark:text-black">
                <Table.Cell>{row.member.fullname}</Table.Cell>
                <Table.Cell>{row.contact.phone}</Table.Cell>
                <Table.Cell>{row.membership.type}</Table.Cell>
                <Table.Cell>{row.lastVisit.duration}</Table.Cell>
                <Table.Cell>{row.billing.amount}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table> */}
      </div>
      <div></div>
    </div>
  );
}
