import { DarkThemeToggle, Label, Select, Sidebar } from "flowbite-react";
import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  SidebarAdminData,
  SidebarEmployeeData,
  SidebarEmployerData,
} from "../../static/SideBarData";
import { RouteLinksActive } from "../../utils/RouteLinksActive";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";

export function MainSideBar({ role }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const localTheme = localStorage.getItem("flowbite-theme-mode");
  const [theme, setTheme] = useState(localTheme);
  const [expandBar, setExpandBar] = useState(false);

  //! MAIN ITEMS SIDEBAR
  const mainItems = useMemo(() => {
    return RouteLinksActive({
      role,
      SidebarAdminData,
      SidebarEmployeeData,
      SidebarEmployerData,
      location,
    });
  }, [role, location]);

  const clickTheme = () => {
    setTheme(localStorage.getItem("flowbite-theme-mode"));
  };

  const changeLang = (e) => {
    localStorage.setItem("lang", JSON.stringify(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  return (
    <div className="dark:bg-gray-800 relative ">
      <div
        className={`absolute top-[50px] right-[-15px] dark:bg-gray-800 bg-gray-50 block rounded-full border-transparent transition-all p-1  z-20 ${
          expandBar && "rotate-180"
        }`}
      >
        <FaChevronLeft
          onClick={() => setExpandBar(!expandBar)}
          size={20}
          className="dark:text-slate-50 cursor-pointer "
        />
      </div>
      <Sidebar
        aria-label="Sidebar"
        className="transition-all [&>div]:pt-[10px] "
        style={{
          width: expandBar && "77px",
        }}
      >
        <Sidebar.Items className="flex flex-col justify-between h-full ">
          <Sidebar.ItemGroup>
            <div className="flex ml-2 my-3 mb-4">
              {!expandBar &&
                (theme === "light" ? (
                  <img className="" src="/GR2.svg" alt="" />
                ) : (
                  <img className="" src="/GR1.svg" alt="" />
                ))}
              {expandBar &&
                (theme === "light" ? (
                  <img
                    className="w-[35px] dark:fill-black"
                    src="/GR7.svg"
                    alt="logo"
                  />
                ) : (
                  <img
                    className="w-[35px] dark:fill-black"
                    src="/GR5.svg"
                    alt="logo"
                  />
                ))}
            </div>
            {mainItems?.map((link) => (
              <Link key={link.to} to={link.to}>
                <span
                  className={
                    link.active
                      ? `flex items-center justify-start gap-[10px] rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-slate-100 dark:text-white dark:hover:bg-gray-700 bg-slate-100 dark:bg-gray-700 mb-[10px]`
                      : ` flex items-center justify-start gap-[10px] rounded-lg p-2 text-base font-normal text-gray-900 hover:bg-slate-100 dark:text-white dark:hover:bg-gray-700 mb-[10px]`
                  }
                >
                  <div
                    className={` transition-all ${
                      expandBar && "scale-150 m-2"
                    }`}
                  >
                    {link.icon}
                  </div>
                  {!expandBar && t(link.label)}
                </span>
              </Link>
            ))}
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <div className={`flex  transition-all ${expandBar && "flex-col"}`}>
              <div
                onClick={clickTheme}
                className=" bg-white dark:bg-gray-700 rounded-lg flex justify-center"
              >
                <DarkThemeToggle />
              </div>
              {expandBar && (
                <Label
                  htmlFor="gymname"
                  value={t("Lang")}
                  className="mt-[10px]"
                />
              )}
              <Select
                className={`w-full ${expandBar ? "mt-2" : "ml-2"}`}
                onChange={(e) => changeLang(e)}
                defaultValue={JSON.parse(localStorage.getItem("lang"))}
              >
                <option value="en">ENGLISH</option>
                <option value="uz">UZBEK</option>
                <option value="ru">RUSSIAN</option>
              </Select>
            </div>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}
