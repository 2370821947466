import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useGymApi from "../../Api/Admin/useGymApi";
import { Button, Label, TextInput } from "flowbite-react";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import SuperTable from "../../components/SuperTable/SuperTable";
import { createTable } from "../../utils/createTable";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";
import NotFoundData from "../../components/NotFound/NotFoundData";
import useConfirm from "../../hooks/useConfirm";

export default function GymInfo() {
  const { gymId } = useParams();
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = useState();
  const [oldEmployees, setOldEmployees] = useState();
  const [gymName, setGymName] = useState();
  const [isSave, setIsSave] = useState(false);
  const { accessToken } = useSelector((state) => state.Auth);
  const update = useSelector((state) => state.update);
  const { getOneGym, updateGym, deleteGym, mainData, isLoader } = useGymApi();
  const { getStaffs, mainData: staffs } = useEmployeeApi();

  const { confirm, ConfirmComponent } = useConfirm();

  useEffect(() => {
    getOneGym(gymId);
    getStaffs();
    // eslint-disable-next-line
  }, [accessToken, gymId, update]);

  //! Old Employers
  useEffect(() => {
    const oldData = mainData?.data?.employees?.map((item) => ({
      value: item._id,
      label: item.fullname,
    }));
    setMultiSelect(oldData);
    setOldEmployees(oldData);
  }, [mainData?.data]);

  // //! EmployeeIds
  const employeeIds = useMemo(() => {
    if (staffs?.data) {
      const ids = staffs?.data.map((staff) => ({
        value: staff._id,
        label: staff.fullname,
      }));

      return ids;
    }
    // eslint-disable-next-line
  }, [staffs?.data, isLoader]);

  const handleMultiSelect = (selectItem) => {
    setMultiSelect(selectItem);
  };

  //! update Data
  const handleUpdate = (e) => {
    e.preventDefault();
    const ids = multiSelect.map((item) => item.value);
    const mainChange = {
      id: gymId,
      body: { name: gymName || mainData?.data?.name, employees: ids },
    };
    updateGym(mainChange);
    // console.log(mainChange);
    setIsSave(!isSave);
  };
  const confirmDelete = async () => {
    const isConfirm = await confirm("Are you sure you want to delete ?");
    if (isConfirm) {
      deleteGym(gymId);
    }
  };

  const table = createTable(mainData?.data?.employees);
  return (
    <div className="p-[20px]">
      <div className="flex mb-[10px] justify-between">
        <div className="flex gap-[14px] items-center">
          <div className="dark:bg-gray-800 w-[60px] h-[60px] flex justify-center items-center rounded-full overflow-hidden border-2 border-slate-100">
            <img
              className="w-full object-cover h-full"
              src={
                mainData?.data?.logo
                  ? mainData?.data?.logo
                  : "https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
              }
              defaultValue={
                "https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
              }
              alt="logo"
            />
          </div>
          <h2 className="text-[30px] dark:text-white first-letter:uppercase">
            {mainData?.data?.name}
          </h2>
        </div>
        <div>
          <Button
            className="text-black"
            onClick={() => window.history.back()}
            size={"sm"}
          >
            {t("BackToList")}
          </Button>
        </div>
      </div>
      <hr />
      {/* //! Gym Main Data */}
      <form onSubmit={handleUpdate}>
        <div className="mt-[10px] flex flex-col gap-5">
          <div className="flex items-center justify-between rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 p-6">
            <div className="w-full grid sm:grid-cols-3 lg:grid-cols-6 gap-4">
              <div>
                <div className="mb-2 block">
                  <Label value={t("YourGymName")} />
                </div>
                <TextInput
                  type="text"
                  disabled={!isSave}
                  defaultValue={mainData?.data?.name}
                  onChange={(e) => setGymName(e.target.value)}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={t("city")} />
                </div>
                <TextInput
                  type="text"
                  disabled
                  value={mainData?.data?.city}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={t("country")} />
                </div>
                <TextInput
                  type="text"
                  disabled
                  value={mainData?.data?.country}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={t("address")} />
                </div>
                <TextInput
                  type="text"
                  disabled
                  value={mainData?.data?.address}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={t("TimeZone")} />
                </div>
                <TextInput
                  type="text"
                  disabled
                  value={mainData?.data?.timeZone}
                  required
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={t("TimeFormat")} />
                </div>
                <TextInput
                  type="text"
                  disabled
                  value={mainData?.data?.timeFormat}
                  required
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            {isSave && (
              <>
                <Label value={t("employees")} />
                <MultiSelect
                  oldEmployees={oldEmployees}
                  data={employeeIds}
                  handleMultiSelect={handleMultiSelect}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-[10px] gap-3">
          <div>
            {!isSave && (
              <button
                type="button"
                onClick={() => confirmDelete()}
                className="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent bg-white-700  focus:ring-4 focus:ring-white-300 enabled:hover:bg-white-800 dark:focus:ring-white-900 dark:enabled:hover:bg-white-700 rounded-lg  duration-200  px-3 py-1.5 text-sm text-black dark:text-white bg-red-700 dark:hover:bg-red-800 hover:hover:bg-red-600"
              >
                {t("DeleteGym")}
              </button>
            )}
          </div>
          {isSave ? (
            <Button className="text-black" type="submit" size={"sm"}>
              {t("saveGym")}
            </Button>
          ) : (
            <button
              type="button"
              onClick={() => setIsSave(!isSave)}
              className="group relative flex items-stretch justify-center p-0.5 text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none border border-transparent bg-cyan-700  focus:ring-4 focus:ring-cyan-300 enabled:hover:bg-cyan-800 dark:bg-cyan-600 dark:focus:ring-cyan-800 dark:enabled:hover:bg-cyan-700 rounded-lg  duration-200  px-3 py-1.5 text-sm text-black"
            >
              {t("editGym")}
            </button>
          )}
        </div>
      </form>
      {/* //! Gym Employers */}
      <h3 className="my-[5px] text-[20px] dark:text-white first-letter:uppercase">
        {t("gymEmployees")}
      </h3>
      <hr />
      <div className="mt-[10px]">
        <SuperTable
          hiddenKeys={["_id", "__v", "password", "employerId", "gymIds"]}
          table={table}
        />
        {!mainData?.data?.employees.length > 0 && !isLoader && (
          <NotFoundData title="Gym Employee not found" height="calc(100vh - 380px)" />
        )}
      </div>
      {isLoader && <Loader />}
      {ConfirmComponent}
    </div>
  );
}
