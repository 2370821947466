import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearItems } from "../../store/Slices/AuthSlice";

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    dispatch(clearItems());
    navigate("/");
    // eslint-disable-next-line
  }, []);

  return <div></div>;
}
