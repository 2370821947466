import React, { useEffect, useMemo, useState } from "react";
import SuperTable from "../../components/SuperTable/SuperTable";
import { createTable } from "../../utils/createTable";
import useEmployerApi from "../../Api/Admin/useEmployerApi";
import { useSelector } from "react-redux";
import SuperPagination from "../../components/pagination/SuperPagination";
import TableSkleton from "../../components/TableSkleton/TableSkleton";
import NotFoundData from "../../components/NotFound/NotFoundData";
import useConfirm from "../../hooks/useConfirm";

export default function EmployerList() {
  const [page, setPage] = useState(1);
  const { getEmployers, mainData, isLoader, error } = useEmployerApi();
  const { accessToken } = useSelector((state) => state.Auth);
  const updateData = useSelector((state) => state.update);
  const { confirm, ConfirmComponent } = useConfirm();

  useEffect(() => {
    getEmployers(page);
    // eslint-disable-next-line
  }, [accessToken, page, updateData]);

  const maxPage = useMemo(() => {
    const calcPage = Math.ceil(mainData?.employersCount / mainData?.pageSize);
    if (calcPage === NaN) return undefined;

    return calcPage;
  }, [mainData]);

  const table = createTable(mainData?.data || []);

  return (
    <div
      className={"px-[15px] mt-[10px]  flex flex-col justify-between h-full"}
    >
      {!table && isLoader && (
        <div className="mt-[30px]">
          <TableSkleton />
        </div>
      )}

      {!isLoader && (error.status || !table?.data?.length > 0) ? (
        <NotFoundData
          title={error?.message || "Employer malumotlari mavjud emas"}
          height="calc(100vh - 160px)"
        />
      ) : (
        <SuperTable
          role={"admin"}
          table={table}
          hiddenKeys={["_id"]}
          isLink={true}
          del={true}
          type={"employer"}
          confirm={confirm}
        />
      )}

      <div className="p-3">
        {maxPage && maxPage >= 1 ? (
          <SuperPagination
            setPage={setPage}
            maxPage={maxPage}
            className="mt-auto"
          />
        ) : (
          ""
        )}
      </div>
      {ConfirmComponent}
    </div>
  );
}
