import React from "react";
import { Outlet } from "react-router-dom";
import { MainSideBar } from "../components/SideBar/MainSideBar";

export default function AdminLayout({ role }) {
  return (
    <div className="flex w-full h-screen">
      <MainSideBar role={role} />
      <div className="w-full h-screen overflow-x-hidden z-[1]">
        <Outlet />
      </div>
    </div>
  );
}
