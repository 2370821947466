import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateData } from "../../store/Slices/UpdateData";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export default function useEmployeeApi() {
  const [mainData, setMainData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState({
    status: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { accessToken } = useSelector((state) => state.Auth);

  //! Get Staffs
  const getStaffs = async (page) => {
    try {
      if (accessToken) {
        setError({
          status: false,
        });
        setIsLoader(true);
        const response = await axiosPrivate.get(
          page
            ? `employee/pagination?page=${page}`
            : `employee/pagination?pageSize=1000`
        );

        setMainData(response.data);
      }
    } catch (error) {
      setError({
        status: true,
        message: error.message,
      });
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  //! Get one Staff
  const getOneStaff = async (id) => {
    try {
      if (accessToken) {
        setIsLoader(true);
        const response = await axiosPrivate.get(`employee/${id}`);
        setMainData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };
  //! Post Staff
  const postStaff = async (body) => {
    try {
      if (accessToken) {
        setIsLoader(true);
        const response = await axiosPrivate.post(`employee/register`, body);
        toast.success(response.data.message);
        window.history.back();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Delete Staff
  const deleteStaff = async (id) => {
    setIsLoader(true);
    try {
      if (accessToken) {
        const response = await axiosPrivate.delete(`employee/${id}`);
        toast.success(response.data.message);
        dispatch(updateData());
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Get Members
  const getMembers = async (gymId) => {
    setIsLoader(true);
    setError({
      status: false,
    });
    try {
      const response = await axiosPrivate.get(
        `member/pagination?gymId=${gymId}`
      );
      setMainData(response.data.data);
      console.log(response);
    } catch (err) {
      setError({
        status: true,
        message: err.message,
      });
      console.log(err);
    } finally {
      setIsLoader(false);
    }
  };
  //! createMember
  const createMember = async (body) => {
    setIsLoader(true);
    try {
      const response = await axiosPrivate({
        url: "member/register",
        method: "POST",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      toast.success(response.data.message);
      window.history.back();
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Get one Member
  const getOneMember = async (id) => {
    setIsLoader(true);
    try {
      if (accessToken) {
        const response = await axiosPrivate.get(`member/${id}`);
        setMainData(response.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Edit Member
  const editMember = async ({ id, body }) => {
    setIsLoader(true);
    try {
      const response = await axiosPrivate({
        url: `member/${id}`,
        method: "PUT",
        data: body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(response.data.message);
      dispatch(updateData(true));
    } catch (err) {
      console.log(err);
      toast.success(err.response?.data?.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Delete Member
  const deleteMember = async (id) => {
    setIsLoader(true);
    try {
      const response = await axiosPrivate.delete(`member/${id}`);
      navigate("/employee/members");
      toast.success(response.data.message);
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setIsLoader(false);
    }
  };

  //! Search Member
  const searchMember = async (data) => {
    setIsLoader(true);
    try {
      const response = await axiosPrivate.get(
        `member/search?page=${data.page}&pageSize=${10}&phone=${data.phone}`
      );
      console.log(response.data);

      // setMainData(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoader(false);
    }
  };
  return {
    mainData,
    isLoader,
    error,
    getStaffs,
    getOneStaff,
    deleteStaff,
    postStaff,
    getMembers,
    createMember,
    getOneMember,
    deleteMember,
    editMember,
    searchMember,
  };
}
