import {
  Button,
  Datepicker,
  Label,
  Radio,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { useMemo, useRef, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaCamera } from "react-icons/fa";
import { t } from "i18next";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";

function AddMember() {
  const activeGym = JSON.parse(localStorage.getItem("activeGym"));
  const { createMember, isLoader } = useEmployeeApi();
  const [confirmPass, setConfirmPass] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isCanvas, setIsCanvas] = useState(false);
  const [snapVideo, setSnapVideo] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [snapPhoto, setSnapPhoto] = useState(null);
  const [filePhoto, setFilePhoto] = useState();
  const [stream, setStream] = useState(null);

  const password = useMemo(() => {
    return confirmPass.password === confirmPass.confirmPassword;
  }, [confirmPass]);

  const handleImageChangePhoto = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFilePhoto(file);
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
      stopCam();
    }

    if (snapPhoto) {
      setSnapPhoto(null);
    }
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const formdata = new FormData(event.target);

    if (snapPhoto) {
      formdata.append("image", snapPhoto);
    }

    if (photo) {
      formdata.append("image", filePhoto);
    }
    if (activeGym) {
      formdata.append("gymId", activeGym);
    }
    for (let key of formdata) {
      console.log(key[0], key[1]);
    }
    createMember(formdata);
  };

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  function getCam() {
    console.log(videoRef.current);

    async function getCameraStream() {
      setSnapVideo(true);
      setIsCanvas(false);
      setPhoto(null);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera:", err);
      }
    }
    getCameraStream();
  }

  const stopCam = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
      setSnapVideo(false);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const takePicture = () => {
    const context = canvasRef.current.getContext("2d");

    context.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    // Kanvasdagi rasmni Base64 formatida olish
    const imageDataURL = canvasRef.current.toDataURL("image/png");

    // Base64 ma'lumot qatorini blobga aylantirish
    const byteString = atob(imageDataURL.split(",")[1]);
    const mimeString = imageDataURL.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeString });

    // Blobni faylga aylantirish
    const file = new File([blob], "memberphoto.png", { type: mimeString });

    setSnapPhoto(file);

    setSnapVideo(false);
    setIsCanvas(true);
    stopCam();
  };

  return (
    <form onSubmit={handleSubmitForm} className="h-full relative p-[15px]">
      {/* Member Detail */}
      <div className="p-[20px] border-[1px] rounded-lg mb-[30px]">
        <div className="flex dark:text-white gap-[40px] mb-[30px] ">
          <div className="flex items-center gap-[10px]">
            <BsCheckCircleFill className="scale-150" />{" "}
            <h2 className="text-xl font-semibold"> {t("memberDetails")}</h2>
          </div>
          {/* Radio */}
          <div className="flex gap-5">
            <div className="flex items-center gap-1">
              <Radio id="Visitor" name="type" value="1Fit" defaultChecked />
              <Label htmlFor="Visitor" className="cursor-pointer">
                1Fit
              </Label>
            </div>
            <div className="flex items-center gap-1">
              <Radio id="Member" name="type" value="usual" />
              <Label htmlFor="Member" className="cursor-pointer">
                Usual
              </Label>
            </div>
          </div>
        </div>
        {/* form */}
        <div className="flex gap-[30px]">
          {/* form 1 */}
          <div className="flex w-full flex-col gap-4 flex-1">
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="First"
                  className="text-xs uppercase"
                  value={t("fullname")}
                />
              </div>
              <TextInput
                id="First "
                type="text"
                name="fullname"
                sizing="md"
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="phone"
                  className="text-xs uppercase"
                  value={t("phone")}
                />
              </div>
              <TextInput id="phone" type="tel" name="phone" sizing="md" />
            </div>
          </div>
          {/* Form 2 */}
          <div className="flex w-full flex-col gap-4 flex-1">
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="email"
                  className="text-xs uppercase"
                  value={t("email")}
                />
              </div>
              <TextInput
                id="email"
                type="text"
                sizing="md"
                name="email"
                placeholder="my@email.com"
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="adress"
                  className="text-xs uppercase"
                  value={t("address")}
                />
              </div>
              <TextInput
                id="adress "
                type="text"
                sizing="md"
                name="address"
                placeholder="address"
                required
              />
            </div>
          </div>
          {/* Form 3 */}
          <div className="flex w-full flex-col gap-4 flex-1">
            <div className="mb-1 block">
              <Label
                htmlFor="email"
                className="text-xs uppercase"
                value={t("gender")}
              />
              <Select className="mt-2 w-[200px]" name="gender">
                <option value="male">{t("male")}</option>
                <option value="female">{t("female")}</option>
              </Select>
            </div>
            <div className="mb-1 block">
              <Label
                id="day"
                htmlFor="large"
                value={t("birthdate")}
                className="text-xs  uppercase"
              />
              <Datepicker
                id="day"
                className="w-[200px] mt-1"
                name="birthdate"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Photo */}
      <div className="flex p-[20px] border-[1px] rounded-lg mb-[30px]">
        <div className="flex gap-[10px] items-center mr-[30px] dark:text-white ">
          <BsCheckCircleFill className="scale-150" />{" "}
          <h2 className="text-xl font-semibold ">{t("photo")}</h2>
        </div>
        <div className="flex gap-[50px]">
          <div className="w-[100px] h-[100px] border-[1px] rounded-full flex items-center justify-center bg-gray-500 overflow-hidden">
            <video
              ref={videoRef}
              autoPlay
              className="w-[140%] h-full object-cover"
              style={{
                display: snapVideo ? "block" : "none",
              }}
            ></video>
            <canvas
              ref={canvasRef}
              className="w-[140%] h-full"
              style={{
                display: isCanvas ? "block" : "none",
              }}
            ></canvas>
            <img
              src={photo}
              style={{ display: isCanvas || !photo ? "none" : "block" }}
              className="w-full h-full object-cover object-center"
              alt=""
            />
            {!photo && <FaCamera className="dark:text-white scale-150" />}
          </div>
          <div className="flex gap-[30px] items-center">
            {snapVideo ? (
              <button
                className="flex items-center gap-3 py-3 dark:bg-white px-4 dark:shadow-[rgba(255,255,255,0.25)_0px_6px_12px_-2px,rgba(255,255,255,0.3)_0px_3px_7px_-3px] shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-[40px] text-[15px] uppercase font-bold leading-relaxed tracking-wider"
                type="button"
                onClick={(e) => takePicture()}
              >
                {t("takeApicture")}
              </button>
            ) : (
              <button
                type="button"
                className="flex items-center gap-3 py-3 dark:bg-[#b2f203] px-4 dark:shadow-[rgba(255,255,255,0.25)_0px_6px_12px_-2px,rgba(255,255,255,0.3)_0px_3px_7px_-3px] shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-[40px] text-[15px] uppercase font-bold leading-relaxed tracking-wider"
                onClick={(e) => getCam(e)}
              >
                {t("captureFromCamera")}
              </button>
            )}
            <label
              onClick={() => setIsCanvas(false)}
              htmlFor="upload"
              className="flex items-center gap-3 py-3 dark:bg-[#b2f203] px-4 dark:shadow-[rgba(255,255,255,0.25)_0px_6px_12px_-2px,rgba(255,255,255,0.3)_0px_3px_7px_-3px] shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-[40px] cursor-pointer"
            >
              <span className="text-[15px] uppercase font-bold leading-relaxed tracking-wider">
                {t("uploadPhoto")}
              </span>
            </label>
            <input
              type="file"
              id="upload"
              className="hidden"
              onChange={handleImageChangePhoto}
            />
          </div>
        </div>
      </div>

      {/*//! Password */}
      <div className="flex p-[20px] border-[1px] rounded-lg mb-[30px] gap-[15px]">
        <div className="flex flex-col w-[300px]">
          <div className="flex gap-[10px] items-center mr-[30px] dark:text-white ">
            <BsCheckCircleFill className="scale-150" />{" "}
            <h2 className="text-xl font-semibold ">{t("accountPassword")}</h2>
          </div>
          <p className="dark:text-white mt-[10px]">
            Please pick a password to log-in to your account later.
          </p>
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="pass"
              className="text-xs uppercase"
              value="Password (minimum 8 characters)"
            />
          </div>
          <TextInput
            id="pass "
            type="password"
            name="password"
            sizing="md"
            required
            placeholder="********"
            onChange={(e) =>
              setConfirmPass({ ...confirmPass, password: e.target.value })
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label
              htmlFor="confirm"
              className={`text-xs uppercase `}
              style={{
                color: password ? "" : "red",
              }}
              value="Confirm Password"
            />
          </div>
          <TextInput
            id="confirm "
            type="password"
            sizing="md"
            placeholder="********"
            onChange={(e) =>
              setConfirmPass({
                ...confirmPass,
                confirmPassword: e.target.value,
              })
            }
          />
        </div>{" "}
      </div>
      <div className="flex justify-end">
        <Button className="dark:text-black" type="submit">
          {isLoader ? (
            <Spinner aria-label="Default status example" />
          ) : (
            t("submit")
          )}
        </Button>
      </div>
    </form>
  );
}

export default AddMember;
