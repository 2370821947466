import {
  Button,
  Card,
  DarkThemeToggle,
  Label,
  Select,
  TextInput,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import { AuthAxios } from "../../Api/AuthAxios";
import { isLoading } from "../../store/Slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

import "./style.css";

export function Login() {
  const [isHidden, setIsHidden] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isForm, setIsForm] = useState(false);
  const { handleSubmit, register, reset } = useForm();
  const { role, accessToken } = useSelector((state) => state.Auth);
  const [expandBar, setExpandBar] = useState(false);

  const submitForm = async (data) => {
    dispatch(isLoading(true));
    AuthAxios(data, dispatch);
    reset();
  };

  useEffect(() => {
    if (accessToken) {
      navigate(`/${role}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const handeleChangeHidden = () => {
    setIsHidden(!isHidden);
  };

  const changeLang = (e) => {
    localStorage.setItem("lang", JSON.stringify(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "h") {
        navigate("/admin/login");
      }
    };

    // Event listenerni o'rnatamiz
    window.addEventListener("keydown", handleKeyDown);

    // Tozalash (cleanup) funksiyasi
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <div className="w-[100%] h-screen login ">
      <div className="flex justify-between">
        <Link to={"/"}>
          <img className="w-[50%]  p-[20px]" src={"/GR1.svg"} alt="logo" />
        </Link>
        {/* <DarkThemeToggle /> */}
        <Select
          className={`w-auto flex items-center justify-center pr-3`}
          onChange={(e) => changeLang(e)}
          defaultValue={JSON.parse(localStorage.getItem("lang"))}
        >
          <option value="en">ENGLISH</option>
          <option value="uz">UZBEK</option>
          <option value="ru">RUSSIAN</option>
        </Select>
      </div>
      <div className="w-full h-[calc(100%-64px)] flex items-center justify-center flex-col">
        {isForm ? (
          <ForgotPassword isForm={isForm} setIsForm={setIsForm} />
        ) : (
          <Card className="w-full sm:w-[400px]">
            <form
              onSubmit={handleSubmit(submitForm)}
              className="flex flex-col gap-4"
            >
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label htmlFor="role" value={t("SelectYourRole")} />
                </div>
                <Select
                  id="role"
                  defaultValue={"employer"}
                  required
                  {...register("role")}
                >
                  <option value={"employee"}>Employee</option>
                  <option value={"employer"}>Employer</option>
                </Select>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value={t("numberLabel")} />
                </div>
                <TextInput
                  id="name"
                  type="tel"
                  placeholder="+998909499898"
                  required
                  {...register("phone")}
                />
              </div>
              <div className=" relative">
                <div className="mb-2 block">
                  <Label htmlFor="name" value={t("Password")} />
                </div>
                <TextInput
                  id="passRole"
                  type={isHidden ? "text" : "password"}
                  placeholder="****************"
                  required
                  {...register("password")}
                />
                <span
                  onClick={handeleChangeHidden}
                  className="block absolute bottom-[10px] right-[15px] cursor-pointer dark:text-white"
                >
                  {!isHidden && <FaEye size={20} />}
                  {isHidden && <FaEyeSlash size={22} />}
                </span>
              </div>
              <div className="flex justify-between">
                <Button className="dark:text-black" type="submit">
                  {t("submit")}
                </Button>
                <p
                  onClick={() => setIsForm(true)}
                  className="text-sm hover:underline cursor-pointer dark:text-white"
                >
                  Forgot password?
                </p>
              </div>
            </form>
          </Card>
        )}
      </div>
    </div>
  );
}
