import axios from "axios";
import { useDispatch } from "react-redux";
import { API_KEY } from "../Api/API_KEY";
import { refreshInfo } from "../store/Slices/AuthSlice";
import { useNavigate } from "react-router-dom";

function useRefreshToken() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = async () => {
    try {
      const res = await axios.post(API_KEY + "auth/refresh-token", {
        refreshToken: JSON.parse(localStorage.getItem("refreshToken")),
      });

      dispatch(
        refreshInfo({
          accessToken: res.data.accessToken,
          role: res.data.role,
          employerId: res.data?._id,
        })
      );
      return res.data.accessToken;
    } catch (err) {
      if (err.response?.status === (403 || 500)) {
        navigate("/");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("role");
      }
    }
  };
  return ref;
}

export default useRefreshToken;
