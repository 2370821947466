import { Button, Modal, Spinner } from "flowbite-react";
import { useEffect } from "react";
import useMemberShipApi from "../../Api/Employee/useMemberShipApi";
import { t } from "i18next";
import formatPrice from "../../utils/formatPrice";

export default function MemberShipModal({ openModal, setOpenModal }) {
  const { getOneMemberShip, deleteMembership, isloading, mainData } =
    useMemberShipApi();
  useEffect(() => {
    if (openModal.id) {
      getOneMemberShip(openModal.id);
    }
  }, [openModal]);

  const deleteMemberShipFn = () => {
    setOpenModal(false);
    deleteMembership(openModal.id);
  };
  return (
    <>
      <Modal
        className="bg-slate-100 dark:bg-gray-800"
        show={openModal.isOpen}
        onClose={() => setOpenModal({ isOpen: false })}
      >
        <Modal.Header>{t("memberships")}</Modal.Header>
        <Modal.Body>
          {isloading && (
            <div className="flex justify-center">
              <Spinner size={"xl"} />
            </div>
          )}

          {!isloading && (
            <div className="dark:text-white flex flex-col gap-2">
              <h2>
                {t("attendanceCount")}: <b>{mainData?.attendanceCount}</b>
              </h2>

              <h2>
                {t("remainingDays")}: <b>{mainData?.remainingDays}</b>
              </h2>

              <h2>
                {t("paymentAmount")}:{" "}
                <b>{formatPrice(mainData?.paymentAmount)}</b>
              </h2>
              <div className="flex gap-3  items-center">
                <h3>{t("duration")}</h3>
                <div className="bg-slate-100 dark:bg-gray-800 flex gap-2 p-1 rounded-md">
                  <h4>
                    {t("from")}: <b>{mainData?.duration?.from}</b>
                  </h4>
                  <h4>
                    {t("to")}: <b>{mainData?.duration?.to}</b>
                  </h4>
                </div>
              </div>
              <div className="flex gap-3 items-center">
                <h3 className="font-semibold">{t("limit")}</h3>
                <div className="bg-slate-100 dark:bg-gray-800 flex gap-2 p-1 rounded-md">
                  <h4>
                    {t("from")}: <b>{mainData?.limit?.from}</b>
                  </h4>
                  <h4>
                    {t("to")}: <b>{mainData?.limit?.to}</b>
                  </h4>
                </div>
              </div>
              <h2 className="font-semibold">
                {t("status")}:{" "}
                <b
                  className={`${
                    mainData?.isActive ? "text-[#b2f203]" : "text-red-600"
                  } `}
                >
                  {mainData?.isActive ? "active" : "noActive"}
                </b>
              </h2>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <Button color="failure" onClick={() => deleteMemberShipFn()}>
              {t("delete")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
