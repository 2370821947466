import { Table } from "flowbite-react";
import TableItems from "./TableItems";
import { uid } from "uid";
import { hiddenTableHead } from "../../utils/hiddenTableHead";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function SuperTable({
  role,
  isLink,
  table,
  hiddenKeys = [],
  del,
  addGym,
  edit,
  setOpenModal,
  setGymId,
  OpenModal,
  view,
  type,
  confirm,
}) {
  const { t } = useTranslation();
  const { column, data } = table || {};

  const newColum = useMemo(() => {
    const newData = hiddenTableHead({ column, hiddenKeys });
    return newData;
  }, [hiddenKeys, column]);

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          {newColum?.map((item) => (
            <Table.HeadCell key={uid()}>{t(item)}</Table.HeadCell>
          ))}
          {(del || addGym || edit || view || OpenModal) && (
            <Table.HeadCell>
              <span className="sr-only">Options</span>
            </Table.HeadCell>
          )}
        </Table.Head>
        <Table.Body className="divide-y">
          {data?.map((item) => (
            <TableItems
              role={role}
              key={uid()}
              data={item}
              column={newColum}
              isLink={isLink}
              del={del}
              edit={edit}
              addGym={addGym}
              setOpenModal={setOpenModal}
              setGymId={setGymId}
              OpenModal={OpenModal}
              view={view}
              type={type}
              confirm={confirm}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
