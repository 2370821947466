import React, { useEffect, useMemo, useState } from "react";
import SuperTable from "../../components/SuperTable/SuperTable";
import { createTable } from "../../utils/createTable";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import SuperPagination from "../../components/pagination/SuperPagination";
import { Spinner } from "flowbite-react";
import TableSkleton from "../../components/TableSkleton/TableSkleton";
import NotFoundData from "../../components/NotFound/NotFoundData";
import Confirm from "../../components/confirm/Confirm";
import useConfirm from "../../hooks/useConfirm";

export default function StaffList() {
  const [page, setPage] = useState(1);
  const { accessToken } = useSelector((state) => state.Auth);
  const updateData = useSelector((state) => state.update);
  const { getStaffs, mainData, isLoader, error } = useEmployeeApi();
  const { confirm, ConfirmComponent } = useConfirm();

  useEffect(() => {
    getStaffs(page);
    // eslint-disable-next-line
  }, [accessToken, updateData]);

  const table = createTable(mainData?.data || []);
  const maxPage = useMemo(() => {
    const calcPage = Math.ceil(mainData?.employersCount / mainData?.pageSize);

    return calcPage;
  }, [mainData]);

  return (
    <div
      className="px-[15px] flex flex-col justify-between "
      style={{
        height: "calc(100vh - 100px)",
      }}
    >
      {isLoader && (
        <div className="mt-[30px]">
          <TableSkleton />
        </div>
      )}

      {!isLoader && (error.status || !table?.data?.length > 0) ? (
        <NotFoundData
          title={error?.message || "Employee malumotlari mavjud emas"}
          height="calc(100vh - 115px)"
        />
      ) : (
        <SuperTable
          role={"employer"}
          table={table}
          hiddenKeys={["_id", "gymIds", "employerId"]}
          isLink={true}
          del={true}
          type={"employer"}
          confirm={confirm}
        />
      )}

      {/* {table == null && !isLoader && (
        <h1 className="text-center dark:text-white font-semibold">Not fount</h1>
      )} */}
      {maxPage && maxPage >= 1 ? (
        <SuperPagination
          setPage={setPage}
          maxPage={maxPage}
          className="mt-auto"
        />
      ) : (
        ""
      )}
      {ConfirmComponent}
    </div>
  );
}
