import { Card, Label, TextInput, Button } from "flowbite-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function ForgotPassword({ isForm, setIsForm }) {
  const [isSendSMS, setIsSendSMS] = useState(false);
  const [isSMSRight, setIsSMSRight] = useState(false);
  const { handleSubmit, register } = useForm();

  const handleSubmitForm = (data) => {
    if (isSendSMS && !isSMSRight) {
      console.log("login", data);
      if (data.sms === "123456") {
        setIsSMSRight(true); // Replace with your actual SMS verification code
      } else {
        toast.error("wrong sms code");
      }
    } else if (isSMSRight) {
      const { password, confirmPass } = data;

      if (password === confirmPass) {
        toast.success("Password changed successfully");
        setIsForm(!isForm);
      } else {
        toast.error("Passwords do not match");
      }
    } else {
      console.log("forgot password", data);
      toast.success("Sms sent to your phone");
      setIsSendSMS(true);
    }
  };

  return (
    <Card className="w-[400px]">
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-4"
      >
        {!isSMSRight && (
          <>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Phone Number" />
              </div>
              <TextInput
                id="name"
                type="tel"
                placeholder="Phone Number"
                required
                {...register("phone")}
              />
            </div>
            {isSendSMS && (
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="SMS Code" />
                </div>
                <TextInput
                  id="name"
                  type="tel"
                  placeholder="Verification code"
                  required
                  {...register("sms")}
                />
              </div>
            )}
          </>
        )}

        {isSMSRight && (
          <>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="New Password" />
              </div>
              <TextInput
                id="name"
                type="password"
                placeholder="New Password"
                required
                {...register("password")}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="name" value="Confirm Password" />
              </div>
              <TextInput
                id="name"
                type="password"
                placeholder="Confirm Password"
                required
                {...register("confirmPass")}
              />
            </div>
          </>
        )}

        <Button className="dark:text-black" type="submit">
          Submit
        </Button>
      </form>
    </Card>
  );
}
