import React from "react";
import { Outlet } from "react-router-dom";
import PageNavbar from "../components/Navbars/PageNavbar";

export default function PageLayout({ items, role }) {
  return (
    <div className=" relative">
      <PageNavbar items={items?.links} title={items?.title} role={role} />
      <div>
        <Outlet />
      </div>
    </div>
  );
}
