const createTable = (data) => {
  if (data?.length > 0) {
    let columns = Object.keys(data[0]);
    let rows = data;

    return { column: columns, data: rows };
  }
  return null;
};
export { createTable };
