import { createSlice } from "@reduxjs/toolkit";
import { userInfo } from "../../utils/userInfo";

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    role: userInfo().role,
    loading: false,
    accessToken: null,
    employerId: null,
  },
  reducers: {
    saveUser(state, action) {
      state.accessToken = action.payload.accessToken;
      state.role = action.payload.role;
      state.loading = false;
    },
    isLoading(state) {
      state.loading = true;
    },
    clearItems(state) {
      state.accessToken = null;
      state.role = null;
    },
    refreshInfo(state, action) {
      state.accessToken = action.payload.accessToken;
      state.role = action.payload.role;
    },
  },
});
export const { saveUser, isLoading, clearItems, refreshInfo } =
  AuthSlice.actions;
export default AuthSlice.reducer;
