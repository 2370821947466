import { Button, Label, Spinner, TextInput } from "flowbite-react";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useEmployerApi from "../../Api/Admin/useEmployerApi";

export default function AddEmployer() {
  const { postEmployer, isLoader } = useEmployerApi();
  const { handleSubmit, register, reset } = useForm();
  const { t } = useTranslation();
  const registerEmployer = (data) => {
    postEmployer(data);
    reset();
    window.history.back();
  };

  return (
    <div className="w-full flex justify-center">
      <form
        onSubmit={handleSubmit((data) => registerEmployer(data))}
        className="flex flex-col gap-4 border-2 border-gray-700 bg-slate-50 dark:bg-transparent dark:border-white p-[20px] rounded-lg w-[500px] mt-[10px]"
      >
        <div>
          <div className="mb-2 block">
            <Label htmlFor="name" value={t("fullname")} />
          </div>
          <TextInput
            id="name"
            type="tel"
            placeholder={t("name")}
            required
            {...register("fullname")}
          />
        </div>{" "}
        <div>
          <div className="mb-2 block">
            <Label htmlFor="employerName" value={t("numberLabel")} />
          </div>
          <TextInput
            id="employerName"
            type="tel"
            placeholder={t("phone")}
            required
            {...register("phone")}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="employerPassword" value={t("Password")} />
          </div>
          <TextInput
            id="employerPassword"
            type="password"
            placeholder="*****"
            required
            {...register("password")}
          />
        </div>
        <Button className="dark:text-black flex justify-center" type="submit">
          {isLoader && <Spinner />}
          {t("submit")}
        </Button>
      </form>
    </div>
  );
}
