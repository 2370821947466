export const userData = [
  {
    id: 1,
    year: 2016,
    userGain: 8000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 13000,
    userLost: 1000,
  },
  {
    id: 3,
    year: 2018,
    userGain: 7000,
    userLost: 300,
  },
  {
    id: 4,
    year: 2019,
    userGain: 6000,
    userLost: 200,
  },
  {
    id: 5,
    year: 2020,
    userGain: 5000,
    userLost: 100,
  },
  {
    id: 6,
    year: 2021,
    userGain: 4000,
    userLost: 50,
  },
  {
    id: 1,
    year: 2016,
    userGain: 8000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 13000,
    userLost: 1000,
  },
  {
    id: 3,
    year: 2018,
    userGain: 7000,
    userLost: 300,
  },
  {
    id: 4,
    year: 2019,
    userGain: 6000,
    userLost: 200,
  },
  {
    id: 5,
    year: 2020,
    userGain: 5000,
    userLost: 100,
  },
  {
    id: 6,
    year: 2021,
    userGain: 4000,
    userLost: 50,
  },
  {
    id: 1,
    year: 2016,
    userGain: 8000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 13000,
    userLost: 1000,
  },
  {
    id: 3,
    year: 2018,
    userGain: 7000,
    userLost: 300,
  },
  {
    id: 4,
    year: 2019,
    userGain: 6000,
    userLost: 200,
  },
  {
    id: 5,
    year: 2020,
    userGain: 5000,
    userLost: 100,
  },
  {
    id: 6,
    year: 2021,
    userGain: 4000,
    userLost: 50,
  },
  {
    id: 1,
    year: 2016,
    userGain: 8000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 13000,
    userLost: 1000,
  },
  {
    id: 3,
    year: 2018,
    userGain: 7000,
    userLost: 300,
  },
  {
    id: 4,
    year: 2019,
    userGain: 6000,
    userLost: 200,
  },
  {
    id: 5,
    year: 2020,
    userGain: 5000,
    userLost: 100,
  },
  {
    id: 6,
    year: 2021,
    userGain: 4000,
    userLost: 50,
  },
  {
    id: 1,
    year: 2016,
    userGain: 8000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 13000,
    userLost: 1000,
  },
  {
    id: 3,
    year: 2018,
    userGain: 7000,
    userLost: 300,
  },
  {
    id: 4,
    year: 2019,
    userGain: 6000,
    userLost: 200,
  },
  {
    id: 5,
    year: 2020,
    userGain: 5000,
    userLost: 100,
  },
  {
    id: 6,
    year: 2021,
    userGain: 4000,
    userLost: 50,
  },
];
