import axios from "axios";
import { API_KEY } from "./API_KEY";
import { toast } from "react-toastify";
import { saveUser } from "../store/Slices/AuthSlice";

const AuthAxios = async (userData, dispatch) => {
  const { role, phone, password } = userData;

  try {
    const response = await axios.post(API_KEY + `${role}/login`, {
      phone,
      password,
    });

    console.log(response);
    if (response.status === 200) {
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(response.data.refreshToken)
      );

      localStorage.setItem("role", JSON.stringify(role));
      dispatch(saveUser({ accessToken: response.data.accessToken, role }));
      toast.success(response.data.message);
    } else {
      throw new Error("error");
    }
  } catch (err) {
    toast.error(err?.response?.data.message);
  }
};

export { AuthAxios };
