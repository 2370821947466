import React from "react";
import "./Loader.css";

export default function Loader() {
  return (
    <div
      className={`absolute top-1/2 h-[calc(100vh-79.5px)] left-1/2 flex justify-center items-center -translate-x-1/2 -translate-y-1/2`}
    >
      <div className="loader">
        <div className="box-load1"></div>
        <div className="box-load2"></div>
        
        <div className="box-load3"></div>
      </div>
    </div>
  );
}
