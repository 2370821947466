import styled from "styled-components";

export const ConfirmStyle = styled.div`
  width: 500px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 25%;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: -4px -3px 45px 5px rgba(0, 0, 0, 0.35);

  .confirmTitle {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
  }
`;
