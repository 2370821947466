import { HiChartPie, HiUser } from "react-icons/hi";
import { BiLogOut } from "react-icons/bi";
import { IoPeopleSharp } from "react-icons/io5";
import { CgGym } from "react-icons/cg";
import { FaUserCheck } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";

export const SidebarAdminData = [
  { icon: <HiChartPie size={20} />, label: "Dashboard", to: "" },
  {
    icon: <IoPeopleSharp size={20} />,
    label: "Employers",
    to: "employers",
  },
  {
    icon: <BiLogOut size={20} />,
    to: "/logout",
    label: "Logout",
  },
];

export const SidebarEmployerData = [
  { icon: <HiChartPie size={20} />, label: "Dashboard", to: "", active: true },
  {
    icon: <GrUserWorker size={20} />,
    label: "Staff",
    to: "staff",
    active: false,
  },
  {
    icon: <CgGym size={20} />,
    label: "gyms",
    to: "gyms",
    active: false,
  },
  {
    icon: <BiLogOut size={20} />,
    to: "/logout",
    label: "Logout",
  },
];
export const SidebarEmployeeData = [
  { icon: <HiChartPie size={20} />, label: "Dashboard", to: "", active: true },
  {
    icon: <HiUser size={20} />,
    label: "members",
    to: "members",
    active: false,
  },
  // {
  //   icon: <FaUserCheck size={20} />,
  //   label: "checkin",
  //   to: "checkin",
  //   active: false,
  // },
  // {
  //   icon: <MdOutlineLocalBar size={20} />,
  //   label: "Bar",
  //   to: "market",
  //   active: false,
  // },
  // {
  //   icon: <MdOutlineCardMembership size={20} />,
  //   label: "memberships",
  //   to: "memberships",
  //   active: false,
  // },
  {
    icon: <BiLogOut size={20} />,
    to: "/logout",
    label: "Logout",
  },
];
