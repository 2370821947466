import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useEmployeeApi from "../../Api/Employer/useEmployeeApi";
import { Button, Datepicker, Label, Spinner, TextInput } from "flowbite-react";
import { FaPencil } from "react-icons/fa6";
import useMemberShipApi from "../../Api/Employee/useMemberShipApi";
import { createTable } from "../../utils/createTable";
import SuperTable from "../../components/SuperTable/SuperTable";
import MemberShipModal from "../../components/MemberShipModal/MemberShipModal";
import TableSkleton from "../../components/TableSkleton/TableSkleton";
import { t } from "i18next";
import SuperPagination from "../../components/pagination/SuperPagination";

export default function MemberDetails() {
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(1);
  const [avatar, setAvatar] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [filteredData, setFiltredData] = useState("");
  const [openModal, setOpenModal] = useState({ isOpen: false, id: null });
  const { memberId } = useParams();
  const { accessToken } = useSelector((state) => state.Auth);
  const update = useSelector((state) => state.update);
  const { getOneMember, deleteMember, editMember, mainData, isLoader } =
    useEmployeeApi();
  const {
    getMemberShip,
    mainData: memberships,
    isloading,
  } = useMemberShipApi();

  const table = createTable(memberships?.data?.data);

  useEffect(() => {
    if (accessToken) {
      getOneMember(memberId);
      getMemberShip({ page, memberId });
    }
  }, [accessToken, update]);

  useEffect(() => {
    const datetime = mainData?.birthdate;
    const result = datetime?.slice(0, 10);
    const finalResult = result?.replace(/-/g, " ");
    setFiltredData(finalResult);
  }, [mainData?.birthdate]);

  const deleteMemberFn = () => {
    if (window.confirm("ok?")) {
      deleteMember(memberId);
    }
  };
  const editMemberFn = async (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    filteredData && formdata.append("birthdate", filteredData);
    file && formdata.append("image", file);
    // : formdata.append("image", mainData?.image);

    for (let key of formdata) {
      console.log(key[0], key[1]);
    }

    editMember({ id: memberId, body: formdata });
    setIsEdit(!isEdit);
  };

  const handeleAvatar = (e) => {
    const myFile = e.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setAvatar(reader.result);
      setFile(myFile);
    };
    reader.readAsDataURL(myFile);
  };

  // console.log(table);
  const maxPage = useMemo(() => {
    const calcPage = Math.ceil(memberships?.data?.dataCount / 10);
    console.log(calcPage);
    return calcPage;
  }, [memberships]);

  return (
    <>
      <div className="p-4">
        <form onSubmit={(e) => editMemberFn(e)}>
          <div className="flex gap-3">
            <div className="relative w-[100px] group">
              <div className=" w-[90px] h-[90px] rounded-full overflow-hidden border-[1px] border-[#b2f203] flex justify-center items-center bg-slate-50">
                {isLoader && <Spinner size={"xl"} />}
                {!isLoader && (
                  <img
                    src={avatar ? avatar : mainData?.image}
                    alt="avatar"
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
              {isEdit && (
                <label
                  id="editImage"
                  className="w-[40px] h-[40px] bg-gray-800 flex justify-center items-center rounded-full absolute top-0 right-[-10px] opacity-0 transition group-hover:opacity-100 cursor-pointer"
                >
                  <input
                    onChange={handeleAvatar}
                    type="file"
                    className="hidden"
                  />
                  <FaPencil size={20} className="text-white" />
                </label>
              )}
            </div>
            <div className="flex items-center flex-wrap gap-2">
              <div>
                <Label>{t("fullname")}</Label>
                <TextInput
                  className="text-[#b2f203] mt-1"
                  type="text"
                  name="fullname"
                  placeholder="Name"
                  defaultValue={mainData?.fullname}
                  disabled={!isEdit}
                />
              </div>
              <div>
                <Label>{t("phone")}</Label>
                <TextInput
                  className="text-[#b2f203] mt-1"
                  type="text"
                  placeholder="Phone"
                  name="phone"
                  defaultValue={mainData?.phone}
                  disabled={!isEdit}
                />
              </div>
              <div>
                <Label>{t("address")}</Label>
                <TextInput
                  className="text-[#b2f203] mt-1"
                  type="text"
                  name="address"
                  placeholder="Address"
                  defaultValue={mainData?.address}
                  disabled={!isEdit}
                />
              </div>
              <div>
                <Label>{t("birthdate")}</Label>
                <Datepicker
                  value={filteredData}
                  id="day"
                  className="w-[180px] mt-1"
                  name="birthdate"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-3">
            {isEdit && (
              <Button type="submit" className="dark:text-black">
                {t("save")}
              </Button>
            )}
            {!isEdit && (
              <Button
                onClick={() => setIsEdit(!isEdit)}
                type="button"
                className="dark:text-black"
              >
                {t("edit")}
              </Button>
            )}
            <Button
              onClick={() => deleteMemberFn()}
              className="dark:bg-white dark:text-black"
              type="button"
            >
              {t("delete")}
            </Button>
          </div>
        </form>

        <h3 className="text-xl dark:text-white mb-2">{t("memberships")}</h3>
        <div className="h-full">
          {!isloading && (
            <SuperTable
              OpenModal={setOpenModal}
              table={table}
              hiddenKeys={[
                "_id",
                "memberId",
                "gymId",
                "attendanceType",
                "attendanceCount",
                "updatedAt",
                "remainingDays",
              ]}
            />
          )}
          {isloading && <TableSkleton />}
          {maxPage && maxPage >= 1 ? (
            <SuperPagination
              setPage={setPage}
              maxPage={maxPage}
              className="mt-auto"
            />
          ) : ""}
          <MemberShipModal setOpenModal={setOpenModal} openModal={openModal} />
        </div>
      </div>
    </>
  );
}
