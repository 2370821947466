import {
  Button,
  Card,
  FileInput,
  Label,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useMemo, useState } from "react";
import MultiSelect from "../components/MultiSelect/MultiSelect";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useEmployerApi from "../Api/Admin/useEmployerApi";
import useGymApi from "../Api/Admin/useGymApi";
import { useSelector } from "react-redux";
import useEmployeeApi from "../Api/Employer/useEmployeeApi";

export default function AddGym({ role }) {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const { accessToken } = useSelector((state) => state.Auth);

  const [logo, setLogo] = useState();
  // eslint-disable-next-line
  const [multiSelect, setMultiSelect] = useState();
  const { employerStaffs, isLoader, mainData } = useEmployerApi();
  const { getOneEmployer, mainData: user } = useEmployerApi();
  const { getStaffs, mainData: staffs } = useEmployeeApi();
  const { postGym } = useGymApi();
  console.log(staffs);

  useEffect(() => {
    if (role === "admin") {
      employerStaffs(employerId);
      getOneEmployer(employerId);
    }
    if (role === "employer") {
      getStaffs();
    }
    // eslint-disable-next-line
  }, [accessToken, role]);

  const employeeIds = useMemo(() => {
    if (role === "employer") {
      if (staffs?.data) {
        return staffs?.data.map((staff) => ({
          value: staff._id,
          label: staff.fullname,
        }));
      }
    }

    if (role === "admin") {
      if (mainData?.employerStaffs?.data) {
        return mainData?.employerStaffs?.data.map((staff) => ({
          value: staff._id,
          label: staff.fullname,
        }));
      }
    }
    // eslint-disable-next-line
  }, [mainData?.employerStaffs?.data, employerStaffs, isLoader, staffs?.data]);

  const handleMultiSelectChange = (selectItem) => {
    setMultiSelect(selectItem);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const render = new FileReader();
      render.onload = () => {
        setLogo(render.result);
      };
      render.readAsDataURL(file);
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    const formdata = new FormData(e.target);
    // for (const [key, value] of formdata) {
    //   console.log(key, value);
    // }
    if (multiSelect?.length > 0) {
      multiSelect.forEach((select) => {
        formdata.append("employees[]", select.value);
      });
    }
    postGym({ employerId, formdata });
  };

  return (
    <>
      <h2 className="text-[25px] ml-[15px] font-semibold dark:text-white  ">
        {isLoader ? "Loading..." : user?.oneEmployer?.data?.fullname}
      </h2>
      <Link to={""}>
        <h3 className="ml-[15px] text-[18px] font-medium underline dark:text-white">
          {user?.oneEmployer?.data?.phone}
        </h3>
      </Link>
      <form
        onSubmit={handleSubmitForm}
        className="w-full flex justify-center mt-[30px]"
      >
        <Card className="w-full max-w-2xl">
          <div className="w-full flex gap-5">
            <div className="w-full flex flex-col gap-3">
              <div className="w-full">
                <div className="mb-2 block">
                  <Label htmlFor="gymname" value={t("YourGymName")} />
                </div>
                <TextInput
                  id="gymname"
                  type="text"
                  name="name"
                  required
                  shadow
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="file-upload" value={t("UploadLogo")} />
                </div>
                <FileInput
                  id="file-upload"
                  onChange={handleFileChange}
                  accept="image/*"
                  name="logo"
                  multiple
                  required
                />
              </div>
              <div className="w-full">
                <div className=" block">
                  <Label htmlFor="phono" value={t("phone")} />
                </div>
                <TextInput id="phone" name="phone" type="tel" required shadow />
              </div>
              <div className="w-full">
                <div className="mb-2 block">
                  <Label htmlFor="gymaddress" value={t("address")} />
                </div>
                <TextInput
                  id="gymaddress"
                  type="text"
                  name="address"
                  required
                  shadow
                />
              </div>
            </div>
            <div className="w-full flex flex-col gap-3">
              <div className="w-full">
                <div className="mb-2 block">
                  <Label htmlFor="city" value={t("city")} />
                </div>
                <TextInput id="city" name="city" type="text" required shadow />
              </div>
              <div className="w-full">
                <div className="mb-2 block">
                  <Label htmlFor="country" value={t("country")} />
                </div>
                <TextInput
                  id="country"
                  name="country"
                  type="text"
                  required
                  shadow
                />
              </div>
              <div className="flex flex-col gap-2">
                <Label htmlFor="time-zone">{t("TimeZone")}</Label>
                <Select id="time-zone" name="timeZone">
                  <option value="UTC+5">UTC+5</option>
                  <option value="GMT+5">GMT+5</option>
                </Select>
              </div>

              <div className="flex flex-col gap-2">
                <Label htmlFor="time-format">{t("TimeFormat")}</Label>
                <Select id="time-format" name="timeFormat">
                  <option value="24h">24h</option>
                  <option value="AM/PM">AM/PM</option>
                </Select>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col gap-2">
            <Label htmlFor="time-format">{t("Employees")}</Label>
            <MultiSelect
              handleMultiSelect={handleMultiSelectChange}
              data={employeeIds}
            />
          </div>
          <Button className="dark:text-black" type="subbmit">
            {isLoader && (
              <div className="flex  justify-center">
                <Spinner size="xl" />
              </div>
            )}
            {t("AddGym")}
          </Button>
        </Card>
      </form>
    </>
  );
}
