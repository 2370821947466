import { createSlice } from "@reduxjs/toolkit";

const EmployeeSlice = createSlice({
  name: "employee",
  initialState: {
    gyms: [],
    gymId: null,
  },
  reducers: {
    setGym(state, action) {
      state.gyms = action.payload;
    },
  },
});

export const { setGym } = EmployeeSlice.actions;
export default EmployeeSlice.reducer;
