const memberLayoutData = {
  links: [
    { link: "", text: "members" },
    { link: "addmember", text: "addMember" },
  ],
};
const memberProfileData = {
  title: "memberProfile",
  links: [
    { link: "", text: "memberInfo" },
    { link: "addmembership", text: "addMembership" },
    // { link: "attendance", text: "attendance" },
  ],
};
const adminEmplyoyerStaffLayoutData = {
  title: "Profile",
  links: [
    { link: "", text: "staffList" },
    { link: "gymlist", text: "gymList" },
    { link: "addgym", text: "addGym" },
  ],
};

const employerStaffProfileLayoutData = {
  title: "Profile",
  links: [{ link: "", text: "gyms" }],
};
const marketLayoutData = {
  links: [
    { link: "", text: "Market" },
    { link: "products", text: "products" },
    { link: "addquantity", text: "Add quantity" },
    { link: "category", text: "Category" },
  ],
};
const stuffLayoutData = {
  links: [
    { link: "", text: "Stafflist" },
    { link: "add", text: "addNewStaff" },
  ],
};
const memberShipLayout = {
  links: [
    { link: "", text: "Memberships List" },
    { link: "add", text: "add Membership" },
  ],
};
const gymsLayoutData = {
  links: [
    { link: "", text: "Gymlist", active: true },
    { link: "add", text: "AddGym", active: false },
  ],
};
const employerLayoutData = {
  links: [
    { link: "", text: "employersList", active: true },
    { link: "add", text: "addEmployer", active: false },
  ],
};
const ProfileOptionsData = {
  title: "Profile Options",
  links: [],
};

export {
  adminEmplyoyerStaffLayoutData,
  employerStaffProfileLayoutData,
  memberLayoutData,
  marketLayoutData,
  stuffLayoutData,
  memberShipLayout,
  gymsLayoutData,
  employerLayoutData,
  ProfileOptionsData,
  memberProfileData,
};
