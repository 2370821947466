import React from "react";
import "./style.css"

export default function NotFound() {
  return (
    <div id="notfoundPage">
      <h1 className="notfoundStatus">404</h1>
      <div class="cloak__wrapper">
        <div class="cloak__container">
          <div class="cloak"></div>
        </div> perspective3d 
      </div>
      <div class="info">
        <h2>We can't find that page</h2>
        <p>
          We're fairly sure that page used to be here, but seems to have gone
          missing. We do apologise on it's behalf.
        </p>
        <a href="/" rel="noreferrer noopener">
          Home
        </a>
      </div>
    </div>
  );
}
