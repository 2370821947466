export const activeClass = (
  defaultClass,
  activeClass,
  nonActiveClass,
  isActive
) => {
  let className = [defaultClass];
  if (isActive) {
    className.push(activeClass);
  } else {
    className.push(nonActiveClass);
  }
  return className.join(" ");
};
