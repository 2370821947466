const userInfo = () => {
  let user = {};
  user.token = localStorage.getItem("refreshToken")
    ? JSON.parse(localStorage.getItem("refreshToken"))
    : null;

  user.role = localStorage.getItem("role")
    ? JSON.parse(localStorage.getItem("role"))
    : null;
  return user;
};

export { userInfo };
