import {
  Button,
  Datepicker,
  Label,
  Select,
  Spinner,
  TextInput,
} from "flowbite-react";
import { t } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useMemberShipApi from "../../Api/Employee/useMemberShipApi";

export default function AddMemberShip() {
  const [isUnlimit, setIsUnlimit] = useState(false);
  const { addMembership, mainData, isloading } = useMemberShipApi();
  const { memberId } = useParams();
  const activeGym = JSON.parse(localStorage.getItem("activeGym"));
  const onSubmitFn = (e) => {
    e.preventDefault();
    const duration = {
      from: e.target[0].value,
      to: e.target[1].value,
    };
    const limit = {
      from: e.target[2].value,
      to: e.target[3].value,
    };
    const attendanceType = e.target[4].value;
    const attendanceCount = isUnlimit ? 0 : +e.target[5].value;
    const paymentAmount = isUnlimit ? +e.target[5].value : +e.target[6].value;
    console.log(e);

    const mainData = {
      gymId: activeGym,
      memberId,
      duration,
      limit,
      attendanceType,
      attendanceCount,
      paymentAmount,
    };
    addMembership(mainData);
  };
  return (
    <div>
      <form onSubmit={onSubmitFn}>
        <div className="flex p-[15px]  justify-center flex-col items-center">
          <div className="w-[70%] flex gap-[30px] border-2 rounded-lg p-4 bg-slate-50 dark:bg-transparent">
            <div className="flex flex-col justify-between gap-3 flex-1">
              <div className="flex flex-col gap-2 bg-slate-100 dark:bg-gray-800 p-2 rounded-md">
                <h6 className="text-[16px] ml-[2px] font-semibold dark:text-white">
                  {t("duration")} :
                </h6>
                <div className="flex gap-[20px]">
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="fromMemberships"
                        value={t("from")}
                        className="text-xs uppercase"
                      />
                    </div>
                    <TextInput
                      type="date"
                      id={"toMemberShip"}
                      minDate={new Date()}
                      required
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="toMemberShip"
                        value={t("to")}
                        className="text-xs uppercase"
                      />
                    </div>
                    <TextInput
                      type="date"
                      id={"toMemberShip"}
                      minDate={new Date()}
                      required
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="flex flex-col gap-2 bg-slate-100 dark:bg-gray-800 p-2 rounded-md">
                <h6 className="text-[16px] ml-[2px] font-semibold dark:text-white">
                  {t("limit")} :
                </h6>
                <div className="flex gap-[20px]">
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="fromMemberships"
                        value={t("from")}
                        className="text-xs uppercase"
                      />
                    </div>

                    <TextInput type="time" required />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="toMemberShip"
                        value={t("to")}
                        className="text-xs uppercase"
                      />
                    </div>

                    <TextInput type="time" required />
                  </div>
                </div>
              </div>
            </div>
            {/* col-2 */}
            <div className="flex flex-col justify-between flex-1">
              <div className="bg-slate-100 dark:bg-gray-800 p-2 rounded-md">
                <div className="mb-2">
                  <Label
                    htmlFor="First"
                    className="text-xs uppercase mb-2"
                    value={t("attendanceCount")}
                  />
                </div>
                <div className="flex gap-[10px]">
                  <Select onChange={() => setIsUnlimit(!isUnlimit)}>
                    <option value="Limited">Limited</option>
                    <option value="Unlimited">Unlimited</option>
                  </Select>
                  {!isUnlimit && (
                    <TextInput
                      id="attendanceCount"
                      type="number"
                      sizing="md"
                      placeholder="Number"
                      className="disabled:opacity-40 "
                      required
                    />
                  )}
                </div>
                <div>
                  <div className="mb-2 block mt-3">
                    <Label
                      htmlFor="paymentsMemberships"
                      className="text-xs uppercase"
                      value="Payment"
                    />
                  </div>
                  <TextInput
                    id="paymentsMemberships"
                    type="number"
                    sizing="md"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-end ">
                <Button type="submit" className="dark:text-black">
                  {isloading ? <Spinner /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
