import React from "react";
import { ConfirmStyle } from "./ConfirmStyle";
import { t } from "i18next";
import { Button } from "flowbite-react";

export default function Confirm({ message, onResolve }) {
  const handleConfirm = (result) => {
    onResolve(result); // Foydalanuvchi qaroriga asoslangan natija qaytariladi
  };
  return (
    <ConfirmStyle className="dark:bg-slate-200 bg-[#eaeae9]">
      <p className="confirmTitle dark:text-white">
        {message && t("confirm") && "Do you want to delete it?"}
      </p>
      <div className="flex w-full justify-center mt-5">
        <div className="flex justify-center w-[200px] gap-3">
          <Button
            color="failure"
            className="dark:text-white"
            onClick={() => handleConfirm(true)}
          >
            {t("delete")}
          </Button>
          <Button
            className="dark:text-black"
            onClick={() => handleConfirm(false)}
          >
            {t("cencel")}
          </Button>
        </div>
      </div>
    </ConfirmStyle>
  );
}
