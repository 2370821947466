import React, { useEffect, useState } from "react";
import SuperTable from "../components/SuperTable/SuperTable";
import { createTable } from "../utils/createTable";
import { Link, useParams } from "react-router-dom";
import useEmployerApi from "../Api/Admin/useEmployerApi";
import { useSelector } from "react-redux";
import { MyModal } from "../components/MyModal/MyModal";
import TableSkleton from "../components/TableSkleton/TableSkleton";
import NotFoundData from "../components/NotFound/NotFoundData";
import useConfirm from "../hooks/useConfirm";

export default function GymList(role) {
  const [openModal, setOpenModal] = useState(false);
  const [gymId, setGymId] = useState(null);
  const { employerId } = useParams();
  const { accessToken } = useSelector((state) => state.Auth);
  const update = useSelector((state) => state.update);
  const { employerGyms, isLoader, mainData: gyms } = useEmployerApi();
  const { getOneEmployer, mainData: user } = useEmployerApi();

  const { confirm, ConfirmComponent } = useConfirm();

  useEffect(() => {
    if (role === "admin") {
      getOneEmployer(employerId);
      employerGyms(employerId);
    }
    if (role === "employer") {
    }
    // eslint-disable-next-line
  }, [accessToken, update]);

  const table = createTable(gyms?.gyms?.data);
  return (
    <div>
      <h2 className="text-[25px] ml-[15px] font-semibold dark:text-white  ">
        {user?.oneEmployer?.data?.fullname}
      </h2>
      <Link to={""}>
        <h3 className="ml-[15px] text-[18px] font-medium underline dark:text-white">
          {user?.oneEmployer?.data?.phone}
        </h3>
      </Link>
      <div className="p-[15px] ">
        {gyms?.gyms?.data && gyms?.gyms?.data.length > 0 ? (
          <SuperTable
            role={"admin"}
            table={table}
            hiddenKeys={["_id", "employees", "employerId"]}
            edit={true}
            del={true}
            setGymId={setGymId}
            setOpenModal={setOpenModal}
            type={"gym"}
            confirm={confirm}
          />
        ) : (
          <NotFoundData
            title={"Gym malumotlari mavjud emas"}
            height="calc(100vh - 150px)"
          />
        )}
        {isLoader && <TableSkleton />}
      </div>
      <MyModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setGymId={setGymId}
        gymId={gymId}
      />
      {ConfirmComponent}
    </div>
  );
}
