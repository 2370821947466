import axios from "axios";
import { API_KEY } from "../API_KEY";

export default axios.create({
  baseURL: API_KEY,
});

export const axiosPrivate = axios.create({
  baseURL: API_KEY,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
