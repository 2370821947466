import React from "react";
import { Outlet } from "react-router-dom";
import { MainSideBar } from "../components/SideBar/MainSideBar";
import { MainNavbar } from "../components/Navbars/MainNavBar";

export default function EmployeeLayout({ role }) {
  return (
    <div className="flex w-full h-screen">
      <MainSideBar role={role} />
      <div className="w-full overflow-x-hidden ">
        <MainNavbar role={role} />
        <Outlet />
      </div>
    </div>
  );
}
