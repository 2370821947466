import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Hello, world!": "Hello, world!",
      Login: "Login",
      numberLabel: "Your Number",
      formLabel: "Remember me",
      submit: "Submit",
      AddGym: "Add Gym",
      password: "Password",
      fullname: "Full Name",
      name: "Name",
      addEmployer: "Add Employer",
      members: "Members",
      employersList: "Employers List",
      phone: "Phone",
      number: "Number",
      Dashboard: "Dashboard",
      ProfileOptions: "Profile Options",
      Staff: "Staffs",
      gyms: "GYMs",
      Bar: "Bar",
      Logout: "Log out",
      Employers: "Employers",
      staffList: "Staff List",
      gymList: "Gym List",
      addGym: "Add Gym",
      Profile: "Profile",
      Employees: "Employees",
      country: "Country",
      city: "City",
      TimeZone: "Time Zone",
      TimeFormat: "Time Format",
      YourGymName: "Your Gym Name",
      UploadLogo: "Upload Logo",
      address: "Address",
      edit: "Edit",
      backToEmployers: "Back to Employers",
      newEmployees: "New Employees",
      gymEmployess: "Gym Employees",
      editGym: "Edit Gym",
      doYouWantToDelete: "Do you want to delete",
      delete: "Delete",
      addNewStaff: "Add New Staff",
      Stafflist: "Staff List",
      newStaff: "New Staff",
      confirmPassword: "Confirm Password",
      passwordMin: "Password (minimum 8 characters)",
      view: "View",
      BackTolist: "Back to list",
      gymEmployees: "Gym Employees",
      SelectYourRole: "Select your role",
      DeleteGym: "Delete Gym",
      Gymlist: "Gym list",
      checkin: "Check In",
      memberProfile: "Member Profile",
      memberInfo: "Member Info",
      addMembership: "Add Membership",
      attendance: "Attendance",
      limit: "Limit",
      addMember: "Add Member",
      gender: "Gender",
      createdAt: "CreatedAt",
      birthdate: "Birthdate",
      duration: "Duration",
      memberships: "Memberships",
      backToList: "Back to list",
      save: "Save",
      attendanceCount: "Attendance count",
      remainingDays: "Remaining days",
      paymentAmount: "Payment amount",
      from: "from",
      to: "to",
      memberDetails: "Member Details",
      male: "Male",
      female: "Female",
      photo: "Photo",
      takeApicture: "Take a picture",
      captureFromCamera: "Capture From Camera",
      uploadPhoto: "Upload Photo",
      accountPassword: "Account Password"
    },
  },
  uz: {
    translation: {
      "Hello, world!": "Salom, dunyo!",
      Login: "Kirish",
      numberLabel: "Raqamingiz",
      formLabel: "Xotiraga saqlash",
      submit: "Qo'shish",
      AddGym: "Gym Qo'shish",
      Password: "Parol",
      fullname: "To'liq ism",
      name: "isim",
      addEmployer: "Ish Boshqaruvchi Qo'shish",
      members: "A'zolar",
      phone: " Telefon Raqam",
      number: "Raqam",
      Dashboard: "Bosh sahifa",
      ProfileOptions: "Profil Sozlamalari",
      Staff: "Ishchilar",
      gyms: "Zallar",
      Bar: "Bar",
      Logout: "Chiqish",
      Employers: "Ish Boshqaruvchi",
      employersList: "Ish Boshqaruvchilar Jadvali",
      staffList: "Ishchilar Jadvali",
      gymList: "Zallar Jadvali",
      addGym: "Zal Qo'shish",
      Profile: "Profil",
      Employees: "Ishchilar",
      country: "Mamlakat",
      city: "Shahar",
      TimeZone: "Vaqt Zona",
      TimeFormat: "Vaqt Format",
      YourGymName: "Sizning Zalingiz",
      UploadLogo: "Rasm Yuklash",
      address: "Manzil",
      edit: "Tahrir",
      backToEmployers: "Ish Boshqaruvchilarga Qaytish",
      newEmployees: "Yangi Ishchilar",
      gymEmployess: "Zal Ishchilar",
      editGym: "Zalni Tahrirlash",
      doYouWantToDelete: "o'chirmoqchimisiz",
      delete: "O'chirish",
      addNewStaff: "Yangi Ishchilar Qo'shish",
      Stafflist: `Ishchilar ro'yxati`,
      newStaff: "Yangi Ishchi",
      confirmPassword: "Tasdiqlash Paroli",
      passwordMin: "Parol (minimum 8 belgidan...)",
      view: "Ko'rish",
      BackToList: "Orqaga Qaytish",
      gymEmployees: "Zal Ishchilari",
      SelectYourRole: "Rolingizni tanlang",
      DeleteGym: "Zalni O'chirish",
      Gymlist: "Zalar Jadvali",
      checkin: "Belgilanish",
      memberProfile: "A'zo Profili",
      memberInfo: "A'zo haqida ma'lumot",
      addMembership: "A'zolik Qo'shish",
      attendance: "Davomat",
      limit: "Chegara",
      addMember: "A'zo Qo'shish",
      gender: "Jins",
      createdAt: "yaratilgan",
      birthdate: "Tug'ilgan kun",
      duration: "Davomiylik",
      memberships: "A'zoliklari ",
      backToList: "Roʻyxatga qaytish",
      save: "Saqlash",
      attendanceCount: "Davomat soni",
      remainingDays: "Qolgan kunlar",
      paymentAmount: "To'lov miqdori",
      from: "dan",
      to: "gacha",
      memberDetails: "A'zo tafsilotlari",
      male: "Erkak",
      female: "Ayol",
      photo: "Rasm",
      takeApicture: "suratga olish",
      captureFromCamera: "Kameradan suratga olish",
      uploadPhoto: "Surat yuklash",
      accountPassword: "Hisob paroli"
    },
  },
  ru: {
    translation: {
      "Hello, world!": "Привет, мир!",
      Login: "Войти",
      numberLabel: "Ваш номер",
      formLabel: "Запомнить меня",
      submit: "Войти",
      AddGym: "Добавить трена... зал",
      Password: "Пароль",
      fullname: "Полное имя",
      name: "имя",
      addEmployer: "Добавить работодателя",
      members: "Участники",
      phone: "Телефон",
      number: "Номер",
      Dashboard: "Панель управления",
      ProfileOptions: "Настройки профиля",
      Staff: "Сотрудники",
      gyms: "Заллы",
      Bar: "Бар",
      Logout: "Выйти",
      Employers: "Работодатели",
      employersList: "Список работодателей",
      staffList: "Список сотрудников",
      gymList: "Список залов",
      addGym: "Добавить зал",
      Profile: "Профиль",
      Employees: "Сотрудники",
      country: "Страна",
      city: "Город",
      TimeZone: "Время",
      TimeFormat: "Формат времени",
      YourGymName: "Ваш зал",
      UploadLogo: "Загрузить логотип",
      address: "Адрес",
      edit: "Редактировать",
      backToEmployers: "Вернуться к работодателям",
      newEmployees: "Новые сотрудники",
      gymEmployess: "Сотрудники залов",
      editGym: "Редактировать зал",
      doYouWantToDelete: "хотите удалить",
      delete: "Удалить",
      addNewStaff: "Добавить нового сотрудника",
      Stafflist: `Список сотрудников`,
      newStaff: "Новые сотрудники",
      confirmPassword: "Подтверждение пароля",
      passwordMin: "Пароль (минимум 8 символов)",
      view: "вид",
      BackToList: "Вернуться к списку",
      gymEmployees: "Работодатели спортзала",
      SelectYourRole: "Выберите свою роль",
      DeleteGym: "Удалить зал",
      Gymlist: "Таблица залов",
      checkin: "Регистрироваться",
      memberProfile: "Профиль участника",
      memberInfo: "информация о участника",
      addMembership: "Добавить членство",
      attendance: "Посещаемость",
      limit: "Предел",
      addMember: "Добавить участника  ",
      gender: "Пол",
      createdAt: "создано в",
      birthdate: "Дата рождения",
      duration: "Продолжительность",
      memberships: "Членство ",
      backToList: "Вернуться к списку",
      save: "Сохранять",
      attendanceCount: "Количество посещений",
      remainingDays: "Оставшиеся дни",
      paymentAmount: "Сумма платежа:",
      from: "от",
      to: "к",
      memberDetails: "Сведения об участниках",
      male: "Мужчина",
      female: "Женщина",
      photo: "Фото",
      takeApicture: "Сфотографируй",
      captureFromCamera: "Захват с камеры",
      uploadPhoto: "Загрузить фото",
      accountPassword: "Пароль учетной записи"
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: JSON.parse(localStorage.getItem("lang")),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
