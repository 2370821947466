import { Button, Label, Modal, TextInput } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useGymApi from "../../Api/Admin/useGymApi";
import { useTranslation } from "react-i18next";
import MultiSelect from "../MultiSelect/MultiSelect";
import useEmployerApi from "../../Api/Admin/useEmployerApi";
import { useNavigate, useParams } from "react-router-dom";

export function MyModal({ setOpenModal, setGymId, openModal, gymId }) {
  const navigate = useNavigate();
  const [oldEmployees, setOLdEmployees] = useState([]);
  const { employerId } = useParams();
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = useState([]);
  const [gymData, setGymData] = useState({});
  const { accessToken } = useSelector((state) => state.Auth);
  const { getOneGym, updateGym, mainData } = useGymApi();
  const { employerStaffs, isLoader, mainData: staffs } = useEmployerApi();

  useEffect(() => {
    if (gymId) {
      getOneGym(gymId);
      employerStaffs(employerId);
    }
    // eslint-disable-next-line
  }, [gymId, accessToken]);

  //! gym data
  useEffect(() => {
    setGymData({ ...gymData, name: mainData?.data?.name });
    // eslint-disable-next-line
  }, [mainData?.data?.name]);

  //! Old Employers
  useEffect(() => {
    const oldData = mainData?.data?.employees?.map((item) => ({
      value: item._id,
      label: item.fullname,
    }));
    setMultiSelect(oldData);
    setOLdEmployees(oldData);
  }, [mainData?.data]);

  //! Close Modal
  const closeModal = () => {
    setOpenModal(false);
    setGymId(null);
    setGymData({});
  };

  //! EmployeeIds
  const employeeIds = useMemo(() => {
    if (staffs?.employerStaffs?.data) {
      const ids = staffs?.employerStaffs?.data.map((staff) => ({
        value: staff._id,
        label: staff.fullname,
      }));

      return ids;
    }
    // eslint-disable-next-line
  }, [staffs?.employerStaffs?.data, isLoader, employerStaffs]);

  const handleMultiSelectChange = (selectItem) => {
    setMultiSelect(selectItem);
  };

  const EditGymFn = (e) => {
    e.preventDefault();
    const employeesIds = multiSelect?.map((item) => {
      return item.value;
    });
    const mainPostData = {
      name: e.target[0].value,
      employees: [...employeesIds],
    };
    console.log(mainPostData);
    // employerGyms(employerId);
    setOpenModal(false);
    updateGym({ id: mainData.data._id, body: mainPostData });
    navigate(-1);
  };

  return (
    <>
      <Modal className="bg-gray-800" show={openModal} onClose={() => closeModal()}>
        <Modal.Header>{t("editGym")}</Modal.Header>
        <form onSubmit={(e) => EditGymFn(e)} className="p-[20px]">
          <div className="w-full">
            <div className="mb-2 block">
              <Label htmlFor="gymname" value={t("YourGymName")} />
            </div>
            <TextInput
              id="gymname"
              type="text"
              defaultValue={isLoader ? "Loading..." : gymData?.name}
              name="name"
              required
              shadow
            />
          </div>

          <div className="w-full flex flex-col gap-2 mt-[15px]">
            <Label htmlFor="time-format">{t("newEmployees")}</Label>
            {oldEmployees && (
              <MultiSelect
                handleMultiSelect={handleMultiSelectChange}
                data={employeeIds}
                oldEmployees={oldEmployees}
              />
            )}
          </div>
          <Button className="mt-[20px] text-black" type="submit">
            {t("submit")}
          </Button>
        </form>
      </Modal>
    </>
  );
}
