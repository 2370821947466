import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./Slices/AuthSlice";
import AdminSlice from "./Slices/AuthSlice";
import { updateData } from "./Slices/UpdateData";
import EmployeeSlice from "./Slices/EmployeeSlice";

const store = configureStore({
  reducer: {
    Auth: AuthSlice,
    Admin: AdminSlice,
    update: updateData,
    staff: EmployeeSlice,
  },
});

export default store;
