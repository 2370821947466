import React from "react";
import "./notfoundData.css";
import styled from "styled-components";

export default function NotFoundData({
  status = 404,
  title = "Look like you're lost",
  height = "100vh",
}) {
  const SectionComp = styled.section`
    font-family: "Nova Square", cursive;
    display: flex;
    justify-content: center;
    align-items: center;

    height: ${(props) => (props.calcheight ? props.calcheight : "100vh")};
  `;

  return (
    <SectionComp className="page_404" calcheight={height}>
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="col-lg-12 text-404">
              <b>{status}</b>
            </div>
            <div className="col-lg-12 text">
              <b> {title} </b>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </SectionComp>
  );
}
